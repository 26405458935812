import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { HERE_API_KEY, bootstrapURLKeys } from '../../constants';
import { darkModeStyle, setZIndexForLayer } from "../Client/Impact/MapManager";
import { ClientId, fullViewportData, getLatLngFromLocation, isValidUserForFeature } from "../../types";
import { MarkerClusterer, SuperClusterViewportAlgorithm } from "@googlemaps/markerclusterer";
import { realColorForSlowdownColor } from "../../types/routes";
import { MapManager } from '../Client/Impact/MapManager';
import { MaskType } from 'src/types/MapType';
import { MVTSource } from 'vector-tiles-google-maps';
import { MapManagerV2 } from '../Client/Impact/MapManagerV2';

export const defaultRouteAndMarkerMapProps = {
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: 0,
        position: 7,
        mapTypeIds: ["satellite", "weather_optics"],
    },

    showTrafficFlow: false,
    showLegacyHereTrafficIncidents: false,
    showTrafficIncidents: false,

    geojsonLayers: [],

    isGovernmentalAlertsVisible: false,

    maskType: MaskType.LabelsAndWater,

    isLocationsVisible: false,
    savedCities: [],

    isVehiclesVisible: false,
    vehicles: [],

    showEventMarkersAboveLabels: false,
};

export const RouteAndMarkerMap = (props) => {
    const [map, setMap] = React.useState(undefined);
    const [maps, setMaps] = React.useState(undefined);
    const [segmentPaths, setSegmentPaths] = React.useState([]);
    const [routeMarkers, setRouteMarkers] = React.useState([]);
    const [currentPosition, setCurrentPosition] = React.useState(undefined);
    const [currentLocationMarker, setCurrentLocationMarker] = React.useState(undefined);
    const [alertsData, setAlertsData] = React.useState(undefined);
    const [selectedAlertData, setSelectedAlertData] = React.useState(undefined);
    const [mapObjectClicked, setMapObjectClicked] = React.useState(false);

    const [trafficFlowLayer, setTrafficFlowLayer] = React.useState(undefined);
    const [trafficIncidentsSource, setTrafficIncidentsSource] = React.useState(undefined);
    const [trafficIncidentsClickHandler, setTrafficIncidentsClickHandler] = React.useState(undefined);

    // this caches the google maps Data objects to prevent recreating/flickering
    // for the passed in geojsonLayers
    const [geojsonLayerDatas, setGeojsonLayerDatas] = React.useState({});

    const [mapManager] = React.useState(() => new MapManager());
    const [mapManagerV2] = React.useState(() => new MapManagerV2());

    const tenMiles = 16093;    // 10 miles in metres
    const [radiusCircles, setRadiusCircles] = React.useState([]);

    // pulling these together to make sure these stay in the correct order
    const alertsZIndex = 95;
    const polylineZIndexWithTiles = 98;
    const polylineZIndexWithoutTiles = 96;
    const roadStatusZindex = 96;
    const routeMarkersZIndexZoomedOut = 96;
    const routeMarkersZIndexZoomedIn = 100;
    const eventsMarkersZIndexBelowLabels = routeMarkersZIndexZoomedOut;
    const eventsMarkersZIndexAboveLabels = routeMarkersZIndexZoomedIn;
    const vehicleMarkersZIndex = 100;
    const weatherTilesZIndex = 97;
    const wildfireZIndex = 99;

    React.useEffect(() => {
        let watchId = undefined;
        if (navigator.geolocation && isRouteReport) {
            watchId = navigator.geolocation.watchPosition(
                function (position) {
                    setCurrentPosition(position);
                },
                function (error) {
                    console.log(`Location Error: ${error.message}`);
                }
            );
        }
        return () => {
            if (watchId) navigator.geolocation?.clearWatch(watchId);
        };
    }, []);

    // track previous segment paths when they change so we can remove old ones
    // (they aren't stored on the map anywhere, so we have to deal with it)
    // https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
    const prevSegmentPathsRef = React.useRef();
    React.useEffect(() => {
        prevSegmentPathsRef.current = segmentPaths;
    });

    const prevRouteMarkersRef = React.useRef();
    React.useEffect(() => {
        prevRouteMarkersRef.current = routeMarkers;
    });

    const [markerClusterer, setMarkerClusterer] = React.useState(undefined);

    const prevMarkerClustererRef = React.useRef();
    React.useEffect(() => {
        prevMarkerClustererRef.current = markerClusterer;
    });

    const prevAlertsDataRef = React.useRef();
    React.useEffect(() => {
        prevAlertsDataRef.current = alertsData;
    });

    const prevSelectedAlertDataRef = React.useRef();
    React.useEffect(() => {
        prevSelectedAlertDataRef.current = selectedAlertData;
    });

    const {
        onCenterChanged, onZoomLevelChanged, onBoundsChanged, onCitySelected, onVehicleSelected, onRouteSelected,
        onNotificationsAvailable, onTrackingModeToggled, onPolylineSelected, onTileLayerLoaded,
        slowdownPolylines, roadRiskPolylines, savedCities, vehicles, timeframe, isRouteReport, routeReportMode,
        trackingMode, selectedView, selectedCity, portalToken,
        onMapClicked, onTrafficIncidentSelected, polylineType, geojsonLayers,
        selectedTimelineView, desiredTileLayer, tileOpacity, maskType, showTrafficFlow,
        showLegacyHereTrafficIncidents, showTrafficIncidents, selectedTrafficIncident, governmentalAlerts, isGovernmentalAlertsVisible,
    } = props;

    const prevSavedCitiesRef = React.useRef();
    const prevSelectedCityIdRef = React.useRef();
    const prevTimeframe = React.useRef();
    const prevSelectedViewRef = React.useRef();
    const prevSelectedTimelineViewRef = React.useRef();
    const prevIsLocationsVisibleRef = React.useRef();

    const prevVehiclesRef = React.useRef();
    const prevSelectedVehicleIdRef = React.useRef();

    const areMapsSetup = React.useRef(false);

    const clearMarkerClusterer = () => {
        const prevMarkerClusterer = prevMarkerClustererRef.current;
        if (prevMarkerClusterer !== undefined) {
            prevMarkerClusterer.reset();
            prevMarkerClusterer.clearMarkers();
            prevMarkerClusterer.setMap(null);
            setMarkerClusterer(undefined);
        }
    };

    React.useEffect(() => {
        // clear marker clustere if not viewing locations or vehicles 
        // update ref do clusterer is recreated corrently next time eithr is viewed
        if (vehicles?.length === 0) {
            clearMarkerClusterer();
            prevVehiclesRef.current = vehicles;
        }
    }, [vehicles]);

    // traffic overlay only works with WO map type so change itto thatwhen trafic overlay is selected
    React.useEffect(() => {
        if (!map || !maps) return;

        if (showTrafficFlow || showLegacyHereTrafficIncidents || showTrafficIncidents) {
            map.setMapTypeId('weather_optics');
        }
    }, [map, maps, showTrafficFlow, showLegacyHereTrafficIncidents, showTrafficIncidents]);

    // effect to set the map to dark mode and set listeners
    React.useEffect(() => {
        if (areMapsSetup.current === true) {
            // no need to set up maps more than once
            return;
        }

        const mapMoved = (map) => {
            const center = map.getCenter();
            onCenterChanged?.({ lat: center.lat(), lng: center.lng() });
            onZoomLevelChanged?.(map.getZoom());
        };

        if (!map || !maps) return;

        var styledMapType = new maps.StyledMapType([...darkModeStyle], { name: "Map" });

        map.mapTypes.set('weather_optics', styledMapType);
        map.setMapTypeId('weather_optics');

        // const labelsLayer = new maps.StyledMapType(labelsStyle, { name: f });
        // map.overlayMapTypes.push(labelsLayer);

        map.addListener('click', function () {
            mapMoved(map);
        });

        map.addListener('dragend', function () {
            mapMoved(map);
        });

        map.addListener('zoom_changed', function () {
            mapMoved(map);
        });

        areMapsSetup.current = true;
    }, [map, maps, onZoomLevelChanged, onCenterChanged]);

    // effect to fit bounds to your selected route once you enter an origin and destination
    React.useEffect(() => {
        if (!map || !maps) return;

        // on initial load the selected route, city and vehicle could be set, only want tocall this 
        // zoom/fit viewport code for the view that is being displayed so it doesnt get canceled out by theothers
        if (selectedView !== 'route') return;

        if (!props.origin || !props.destination) {
            return;
        }

        let minLongitude = Math.min(props.origin.longitude, props.destination.longitude);
        let maxLongitude = Math.max(props.origin.longitude, props.destination.longitude);
        let minLatitude = Math.min(props.origin.latitude, props.destination.latitude);
        let maxLatitude = Math.max(props.origin.latitude, props.destination.latitude);

        map.fitBounds(
            new maps.LatLngBounds({ lat: minLatitude, lng: minLongitude }, { lat: maxLatitude, lng: maxLongitude }),
            props.padding
        );
        // TODO: why does adding props.padding into the deps array cause an infinite render?
    }, [map, maps, props.origin, props.destination, selectedView]);

    // effect to fit the viewport to a new city when it is selected
    React.useEffect(() => {
        if (!map || !maps) return;

        // on initial load the selected route, city and vehicle could be set, only want tocall this 
        // zoom/fit viewport code for the view that is being displayed so it doesnt get canceled out by theothers
        if (selectedView !== 'location') return;

        if (!selectedCity) {
            return;
        }

        const viewportData = fullViewportData(selectedCity);
        if (viewportData === undefined) {
            // cities imported in batches don't have a viewport
            map.panTo(new maps.LatLng({ lat: selectedCity.latitude, lng: selectedCity.longitude }));

            // zoom selected saved locations to a level where clusters won't show
            if (map.getZoom() < 12 && selectedCity.id !== undefined) {
                map.setZoom(12);
            }
        } else {
            // cities added via portal do have a viewport
            map.fitBounds(new maps.LatLngBounds({ lat: viewportData.swLatitude, lng: viewportData.swLongitude }, { lat: viewportData.neLatitude, lng: viewportData.neLongitude }));
        }
    }, [map, maps, selectedCity, selectedView]);

    // effect to draw circles around the selectedCity if we're looking at lightning
    React.useEffect(() => {
        if (!map || !maps) return;

        for (const circle of radiusCircles) {
            circle.setMap(null);
        }

        // TODO: is this necessary?
        if (selectedView !== 'location') return;
        if (!selectedCity) return;
        if (!props.isLightningVisible) return;

        // Create 10, 20, 30 mile radius circles around saved cities
        const circles = [];
        circles.push(new maps.Circle({ map: map, center: { lat: selectedCity.latitude, lng: selectedCity.longitude }, radius: tenMiles / 50, strokeColor: 'white', fillOpacity: 0 }));
        circles.push(new maps.Circle({ map: map, center: { lat: selectedCity.latitude, lng: selectedCity.longitude }, radius: tenMiles, strokeColor: 'white', fillOpacity: 0 }));
        circles.push(new maps.Circle({ map: map, center: { lat: selectedCity.latitude, lng: selectedCity.longitude }, radius: tenMiles * 2, strokeColor: 'white', fillOpacity: 0 }));
        circles.push(new maps.Circle({ map: map, center: { lat: selectedCity.latitude, lng: selectedCity.longitude }, radius: tenMiles * 3, strokeColor: 'white', fillOpacity: 0 }));
        setRadiusCircles(circles);
    }, [map, maps, selectedCity, selectedView, props.isLightningVisible]);

    // effect to fit the viewport to a new vehicle when it is selected
    React.useEffect(() => {
        if (!map || !maps) return;

        // on initial load the selected route, city and vehicle could be set, only want tocall this 
        // zoom/fit viewport code for the view that is being displayed so it doesnt get canceled out by theothers
        if (selectedView !== 'vehicle') return;

        if (vehicles?.length === 0 || !props.selectedVehicle || !props.selectedVehicle.latitude || !props.selectedVehicle.longitude) {
            return;
        }

        map.panTo(new maps.LatLng({ lat: props.selectedVehicle.latitude, lng: props.selectedVehicle.longitude }));

        // zoom to a level where clusters won't show
        if (map.getZoom() < 12) {
            map.setZoom(12);
        }

    }, [map, maps, props.selectedVehicle, selectedView]);

    // effect to render the slowdown polyline when it changes -- and cleanup the old one if needed
    React.useEffect(() => {
        if (!map || !maps) return;

        // Remove all previously-added segment paths
        const prevSegmentPaths = prevSegmentPathsRef.current;
        for (const segment of prevSegmentPaths) {
            segment.setMap(null);
            setSegmentPaths([]);
        }

        if (!slowdownPolylines && !roadRiskPolylines) {
            // no new segments to draw - bail
            return;
        }

        if (!props.selectedRouteOption) {
            console.warn('no selected route option when polylines are present -- this is not valid state for RouteAndMarkerMap');
            return;
        }

        let polylines = slowdownPolylines !== undefined ? slowdownPolylines : roadRiskPolylines;
        if (isRouteReport) {
            polylines = roadRiskPolylines;
        }
        // add all new segments to the map
        let newSegmentPaths = [];
        for (const segment of polylines) {
            const segmentPath = new maps.Polyline({
                path: maps.geometry.encoding.decodePath(segment.polyline),
                strokeColor: realColorForSlowdownColor(segment.color),
                // segment route should only be undefined until we also start showing route options in routes tab
                strokeOpacity: segment.route === undefined ? 1.0 : props.selectedRouteOption.indexOf(segment.route.selectedRouteOption) > -1 ? 1.0 : 0.3,
                strokeWeight: 5,
                zIndex: 10000,
            });
            if (isRouteReport) {
                segmentPath['maxRoadIndex'] = segment.maxRoadIndex;
                segmentPath['color'] = segment.color;
            }
            segmentPath.setMap(map);
            // hack to add polyline to selected option to expand route table row if route is selected from map
            // segment route should only be undefined until we also start showing route options in routes tab
            segment.route && segmentPath.addListener('click', (event) => {
                setMapObjectClicked(true);
                onRouteSelected?.({ ...segment.route, selectedRouteOption: `${segment.route.selectedRouteOption} polyline` });
                if (isRouteReport) {
                    onPolylineSelected?.(segment);
                }
            });
            newSegmentPaths.push(segmentPath);
        }

        setSegmentPaths(newSegmentPaths);

        // hack to makes the polyline show UNDER the place labels map layer
        if (newSegmentPaths.length > 0) {
            setZIndexForLayer(map, 2, desiredTileLayer !== undefined ? polylineZIndexWithTiles : polylineZIndexWithoutTiles);
        }
    }, [map, maps, props.selectedRouteOption, slowdownPolylines, roadRiskPolylines, polylineType, desiredTileLayer]);

    // effect to create route report notification
    React.useEffect(() => {
        if (!map || !maps) return;

        if (!segmentPaths) {
            return;
        }

        if (!currentPosition || routeReportMode !== 'navigation') {
            return;
        }

        // find notifications for new segment pathcs
        let currentNotifications = null;
        for (let segment = 0; segment < segmentPaths.length; segment++) {
            const currentLatLng = new maps.LatLng(currentPosition.coords.latitude, currentPosition.coords.longitude);
            const isCurrentSegment = maps.geometry.poly.isLocationOnEdge(currentLatLng, segmentPaths[segment], 0.01);
            if (isCurrentSegment && segmentPaths[segment].strokeOpacity === 1/* current segment is in selected route */) {
                let distance = maps.geometry.spherical.computeDistanceBetween(currentLatLng, segmentPaths[segment].getPath().getAt(segmentPaths[segment].getPath().length - 1));
                let miles = (distance * 0.000621371192).toFixed(1); // convert meters to the nearest tenth of a mile
                currentNotifications = {};
                currentNotifications['current'] = {
                    color: segmentPaths[segment].color,
                    maxRoadIndex: segmentPaths[segment].maxRoadIndex,
                    distance: miles
                };
                if (segmentPaths.length > segment + 1) {
                    currentNotifications['next'] = {
                        color: segmentPaths[segment + 1].color,
                        maxRoadIndex: segmentPaths[segment + 1].maxRoadIndex,
                        distance: miles
                    };
                }

            }
        }
        onNotificationsAvailable?.(currentNotifications);
    }, [map, maps, props.selectedRouteOption, routeReportMode, currentPosition]);

    // effect to render the route waypoints when they change -- and cleanup the old waypoints if needed
    React.useEffect(() => {
        if (!map || !maps) return;

        // Remove all previously-added route markers
        const prevRouteMarkers = prevRouteMarkersRef.current;
        for (const marker of prevRouteMarkers) {
            marker.setMap(null);
        }

        const missingRouteData = props.origin === undefined || props.destination === undefined || segmentPaths.length === 0;
        // restrict some features in production to only Air Force (2684)
        // TODO: how to differentiate between Dashboard and Routes tab for these features
        // would like to only enable on Routes tab
        const useDifferentColorsForOriginAndDestination = isValidUserForFeature(props.userId, { 'production': [ClientId.Production.AFB, ClientId.Production.UPS, ClientId.Production.Santa_Fe_Indian_School] });
        const showOriginAndDestinationWithoutSegments = isValidUserForFeature(props.userId, { 'production': [ClientId.Production.AFB, ClientId.Production.UPS, ClientId.Production.Santa_Fe_Indian_School] });
        // restrict some features in production to only Air Force (2684)
        if (!showOriginAndDestinationWithoutSegments && missingRouteData) {
            // no route available to draw route markers
            return;
        }

        let markers = [];
        if (props.origin !== undefined) {
            let origin = new maps.Marker({
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    fillColor: (useDifferentColorsForOriginAndDestination || isRouteReport) ? "green" : "blue",
                    fillOpacity: 1.0,
                    strokeColor: "white",
                    strokeWeight: 2.0,
                    scale: 7,
                },
                title: 'Origin',
                // using the first polyline start point for the origin so the origin marker is at the beginning of the polyline path
                position: segmentPaths?.length > 0 ? new maps.LatLng(segmentPaths[0].getPath().getAt(0)) : new maps.LatLng(props.origin.latitude, props.origin.longitude),
                opacity: 1.0,
                map: map,
            });
            origin.addListener('click', (event) => {
                setMapObjectClicked(true);
                handleMapClicked(new maps.LatLng(segmentPaths?.length > 0 ? new maps.LatLng(segmentPaths[0].getPath().getAt(0)) : new maps.LatLng(props.origin.latitude, props.origin.longitude)));
            });
            markers.push(origin);
        }

        if (props.destination !== undefined) {
            let destination = new maps.Marker({
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    // restrict some features in production to only Air Force (2684)
                    fillColor: (useDifferentColorsForOriginAndDestination || isRouteReport) ? "red" : "blue",
                    fillOpacity: 1.0,
                    strokeColor: "white",
                    strokeWeight: 2.0,
                    scale: 7,
                },
                title: 'Destination',
                // using the last polyline end point for the destination so the destination marker is at the end of the polyline path
                position: segmentPaths?.length > 0 ? new maps.LatLng(segmentPaths[segmentPaths.length - 1].getPath().getAt(segmentPaths[segmentPaths.length - 1].getPath().length - 1)) : new maps.LatLng(props.destination.latitude, props.destination.longitude),
                opacity: 1.0,
                map: map,
            });
            destination.addListener('click', (event) => {
                setMapObjectClicked(true);
                handleMapClicked(new maps.LatLng(segmentPaths?.length > 0 ? new maps.LatLng(segmentPaths[0].getPath().getAt(0)) : new maps.LatLng(props.origin.latitude, props.origin.longitude)));
            });
            markers.push(destination);
        }

        props.waypoints?.forEach((waypoint, index) => {
            if (waypoint !== undefined) {
                const marker = new maps.Marker({
                    icon: {
                        url: `data:image/svg+xml;utf-8,
                        <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250"><circle cx="125" cy="125" r="100" fill="white" stroke="black" stroke-width="5"/><text x="50%" y="50%" text-anchor="middle" fill="black" font-size="170px" font-family="Arial" font-weight="bold" dy=".35em">${index + 1}</text></svg>`,
                        scaledSize: new maps.Size(20, 20),
                        origin: new maps.Point(0, 0),
                        anchor: new maps.Point(10, 10),
                    },
                    title: `Waypoint ${index + 1}`,
                    position: new maps.LatLng(waypoint.latitude, waypoint.longitude),
                    opacity: 1.0,
                    map: map,
                });
                marker.addListener('click', (event) => {
                    setMapObjectClicked(true);
                    handleMapClicked(new maps.LatLng(waypoint.latitude, waypoint.longitude));
                });
                markers.push(marker);
            }
        });

        setRouteMarkers(markers);

        if (markers.length > 0) {
            // hack to makes the route makers show UNDER the place labels map layer when zoomed out
            // move route markers below text when zoomed out to make reading text easier
            // move route markers above text when zoomed in to make the exact start/end of the route easier to find
            setZIndexForLayer(map, 4, map.getZoom() < 9 ? routeMarkersZIndexZoomedOut : routeMarkersZIndexZoomedIn);
        }
    }, [map, maps, props.selectedRouteOption, props.origin, props.destination, props.waypoints, segmentPaths, map?.getZoom()]);

    // effect to draw current position marker and move map to track current position
    React.useEffect(() => {
        if (!map || !maps) return;
        if (!isRouteReport || !currentPosition) return;

        const currentLatLng = new maps.LatLng(currentPosition.coords.latitude, currentPosition.coords.longitude);
        if (currentLocationMarker) {
            currentLocationMarker.setPosition(currentLatLng);
        }
        else {
            const currentLocation = new maps.Marker({
                title: 'Current Location',
                position: currentLatLng,
                opacity: 1.0,
                map: map,
            });
            setCurrentLocationMarker(currentLocation);
        }

        if (trackingMode && routeReportMode === 'navigation') {
            map.panTo(currentLatLng);
            map.setZoom(10);
            map.setHeading(currentPosition.coords.heading);
        }
    }, [map, maps, currentPosition, routeReportMode, trackingMode]);

    // effect to render location markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.citiesLoading) {
            return;
        }

        if (prevSavedCitiesRef.current === savedCities
            && prevSelectedCityIdRef.current === props.selectedCity?.id
            && prevTimeframe.current === timeframe?.label
            && prevSelectedViewRef.current === selectedView
            && prevSelectedTimelineViewRef.current === selectedTimelineView
            && prevIsLocationsVisibleRef.current === props.isLocationsVisible
        ) {
            // avoid duplicate effect calls - need to do this because react is a stickler about the dependencies array
            return;
        }
        // used to be set in a separate effect below, but that caused the markers to not show up when
        // returning to the dashboard locations view from another tab
        prevSavedCitiesRef.current = savedCities;
        prevSelectedViewRef.current = selectedView;

        mapManager.setupMarkers(
            savedCities,
            props.isLocationsVisible,
            selectedCity,
            selectedTimelineView,
            timeframe,
            MarkerClusterer,
            SuperClusterViewportAlgorithm,
            (event, location) => {
                event.domEvent.stopPropagation();
                onCitySelected?.(location);
            },
            (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, savedCities, onCitySelected, props.selectedCity, selectedView, timeframe, selectedTimelineView, props.citiesLoading, props.isLocationsVisible]);

    // effect to render vehicle markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.vehiclesLoading) {
            return;
        }

        mapManagerV2.setupVehicles(
            vehicles,
            props.isVehiclesVisible,
            props.selectedVehicle,
            (event, v) => {
                event.domEvent.stopPropagation();
                onVehicleSelected(v);
            },
            (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );

        if (vehicles.length > 0) {
            // hack to makes the route makers show UNDER the place labels map layer when zoomed out
            setZIndexForLayer(map, 4, vehicleMarkersZIndex);
        }
    }, [map, maps, vehicles, props.selectedVehicle, props.vehiclesLoading]);

    React.useEffect(() => {
        if (tileOpacity) {
            mapManager.setTileOpacity(tileOpacity);
        }
    }, [tileOpacity]);

    // updates for changed overlay type
    React.useEffect(() => {
        if (!map || !maps) return;

        if (!governmentalAlerts) return;

        // clear out the old alerts data, we'll fix the visibility in the other effect
        prevAlertsDataRef.current?.setMap(null);

        let alerts = new maps.Data();
        alerts.addGeoJson({
            type: 'FeatureCollection',
            features: governmentalAlerts.map(x => x.geojsonFeature),
        });
        alerts.setStyle(function (feature) {
            const fillColor = feature.getProperty('fill');
            const fillOpacity = feature.getProperty('fill-opacity');
            const priority = feature.getProperty('priority');
            return {
                fillColor,
                fillOpacity,
                strokeWeight: 1,
                strokeColor: '#5A5A5A',
                strokeOpacity: 0.3,
                zIndex: 10000 - priority
            };
        });
        setAlertsData(alerts);
    }, [map, maps, governmentalAlerts]);

    React.useEffect(() => {
        if (!map || !maps) return;

        let timeoutId = undefined;
        if (!alertsData?.getMap() && isGovernmentalAlertsVisible) {
            // TODO: this can cause some lag when the alerts visibility is being toggled on
            // we switched from using loadGeoJson in favor of addGeoJson so that we could
            // use a SSOT of the alerts data. as a result, this made it so that when you
            // toggle on the alerts, the setMap call takes a while and causes the UI to hitch
            // previously, when using loadGeoJson, the setMap call wouldn't happen until the data
            // was loaded a second or two later and no UI hitch was visible. the loadGeoJson behavior
            // can be simulated by using setTimeout(() => alertsData?.setMap(map), 1000), but this
            // just delays the hitch to 1s from now which would be noticeable if you pan the map
            // at that time. this was discovered on a particularly high volume of alerts data
            // so this may be less of an issue in general
            timeoutId = setTimeout(() => alertsData?.setMap(map), 1000);
        } else if (alertsData?.getMap() && !isGovernmentalAlertsVisible) {
            alertsData?.setMap(null);
        }

        if (isGovernmentalAlertsVisible) {
            setZIndexForLayer(map, 2, alertsZIndex);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [map, maps, alertsData, isGovernmentalAlertsVisible]);

    React.useEffect(() => {
        if (!map || !maps) return;

        // clear out the old alerts data, we'll fix the visibility in the other effect
        prevSelectedAlertDataRef.current?.setMap(null);

        if (!props.selectedAlert) return;

        let selectedAlertData = new maps.Data();
        selectedAlertData.addGeoJson({
            type: 'FeatureCollection',
            features: [props.selectedAlert.geojsonFeature],
        });
        selectedAlertData.setStyle(function (feature) {
            const fillColor = feature.getProperty('fill');
            const fillOpacity = feature.getProperty('fill-opacity');
            return {
                fillColor,
                fillOpacity,
                strokeWeight: 2,
                strokeColor: '#FFFFFF',
                strokeOpacity: 1,
                zIndex: 10000
            };
        });
        setSelectedAlertData(selectedAlertData);
    }, [map, maps, props.selectedAlert]);

    React.useEffect(() => {
        if (!map || !maps) return;

        if (!selectedAlertData?.getMap()) {
            selectedAlertData?.setMap(map);
        }
    }, [map, maps, selectedAlertData]);

    React.useEffect(() => {
        if (!map || !maps) return;

        if (showTrafficFlow) {
            let _trafficFlowLayer = trafficFlowLayer;
            if (_trafficFlowLayer === undefined) {
                console.log('creating new traffic flow layer');
                _trafficFlowLayer = new maps.TrafficLayer();
                setTrafficFlowLayer(_trafficFlowLayer);
            }
            if (!_trafficFlowLayer.getMap()) {
                _trafficFlowLayer.setMap(map);
            }
        } else {
            if (trafficFlowLayer?.getMap()) {
                trafficFlowLayer?.setMap(null);
            }
        }
    }, [map, maps, showTrafficFlow]);

    React.useEffect(() => {
        if (!map || !maps) return;

        if (showLegacyHereTrafficIncidents) {
            const layer = setupTrafficIncidentsSource();
            mapManager.showTrafficIncidents(layer);

            trafficIncidentsClickHandler?.remove();
            const clickOptions = {
                multipleSelection: false, // Multiple feature selection
                setSelected: true, // set feature as selected
                toggleSelection: false // toggle selection on click
            };
            setTrafficIncidentsClickHandler(map.addListener("click", (event) => {
                setMapObjectClicked(true);
                layer?.onClick(event, ShowSelectedFeatures.bind(this), clickOptions);
            }));
        } else {
            mapManager.showTrafficIncidents(undefined);

            trafficIncidentsClickHandler?.remove();
            setTrafficIncidentsClickHandler(undefined);
        }
    }, [map, maps, showLegacyHereTrafficIncidents, trafficIncidentsSource]);

    React.useEffect(() => {
        if (selectedTrafficIncident === undefined) {
            trafficIncidentsSource?.deselectAllFeatures();
        }
    }, [selectedTrafficIncident]);

    const setupTrafficIncidentsSource = () => {
        if (trafficIncidentsSource !== undefined) {
            return trafficIncidentsSource;
        }

        const options = {
            url: `https://traffic.vector.hereapi.com/v2/traffictiles/incident/mc/{z}/{x}/{y}/omv?features=premium&apiKey=${HERE_API_KEY}`,
            clickableLayers: ["traffic_incidents"],//, "incident_icons"], // Trigger click event to some layers
            visibleLayers: ["traffic_incidents"],//, "incident_icons"],
            sortKey: 'sort_rank',
            // uncomment if you want to listen to HERE's suggestion on when to show incidents
            // shouldRenderFeature: (feature: any) => {
            //     const minZoom = feature.properties.min_zoom;
            //     if (minZoom === undefined) return true;
            //     const mapZoom = .map.getZoom();
            //     if (mapZoom === undefined) return true;
            //     return mapZoom > minZoom;
            // },
            getIDForLayerFeature: function (feature) {  // Unique identifier for each feature
                return `${feature.properties.id}_${feature.type}`;
            },
            style: function (feature) {
                const warningLevelToColor = {
                    'low': '92,120,1',
                    'minor': '218,218,0',
                    'major': '206,133,0',
                    'critical': '211,16,12',
                };
                const color = warningLevelToColor[feature.properties.warning_level];
                const style = {};
                switch (feature.type) {
                    case 1: //'Point'
                        style.fillStyle = `rgba(${color},0.7)`;
                        style.radius = 10;
                        style.selected = {
                            fillStyle: `rgba(${color},1.0)`,
                            radius: 12
                        };
                        break;
                    case 2: //'LineString'
                        style.strokeStyle = `rgba(${color},0.7)`;
                        style.lineWidth = 5;
                        style.selected = {
                            strokeStyle: `rgba(${color},1.0)`,
                            lineWidth: 7
                        };
                        break;
                    case 3: //'Polygon'
                        break;
                }
                return style;
            }
        };

        const mvtSource = new MVTSource(map, options);
        setTrafficIncidentsSource(mvtSource);
        return mvtSource;
    };

    const ShowSelectedFeatures = (event) => {
        // if (!event.feature && trafficIncidentsSource) {
        //     trafficIncidentsSource.deselectAllFeatures();
        // }
        let features = [];
        if (trafficIncidentsSource) {
            features = trafficIncidentsSource.getSelectedFeatures();
        }

        const feature = features.length > 0 ? features[0] : undefined;
        // HERE incident properties:
        // id: 647208632112100700
        // kind: "construction"
        // min_zoom: 7
        // network: "US"
        // road_kind: "highway"
        // road_kind_detail: "motorway"
        // sort_rank: 0
        // source: "© 2019 HERE"
        // start_time: 1681211774
        // stop_time: 1681253999
        // warning_level: "low"
        const kindToDescription = {
            'accident': 'There has been a collision.',
            'congestion': 'There has been a build up of vehicles.',
            'construction': 'Building or roadworks are taking place.',
            'disabled_vehicle': 'A vehicle is unable to move and is obstructing the road.',
            'lane_restriction': 'Lane(s) have been closed.',
            'mass_transit': 'A large number of people are migrating from one location to another.',
            'planned_event': 'An organised event is taking place, causing disruption.',
            'road_closure': 'Road is closed due to traffic incident, roadworks or public event.',
            'road_hazard': 'There are dangerous objects on the surface of the road.',
            'weather': 'Weather conditions are causing disruptions.',
            'other': 'An incident has occurred.',
        };

        const incidentProperties = feature ? {
            kind: feature.properties.kind,
            description: kindToDescription[feature.properties.kind],
            warningLevel: feature.properties.warning_level,
            startTime: feature.properties.start_time ? new Date(feature.properties.start_time * 1000) : undefined,
            endTime: feature.properties.stop_time ? new Date(feature.properties.stop_time * 1000) : undefined,
            source: feature.properties.source,
        } : undefined;

        // this is the properties of a TrafficIncidentData
        // no type safety here, but, in theory, we'll remove this soon so
        // okay for now

        // id: string;
        // type: EventType;
        // geoJson: GeoJSON.Feature;
        // clickedPoint?: { latitude: number, longitude: number };
        // roadway: string;
        // description?: string;
        // trafficIncidentType?: string;
        // affectedLanes?: string;
        // startTime?: Date;
        // endTime?: Date;
        // source?: string;
        const incident = feature ? {
            id: feature.featureId,
            type: 'traffic_incident',
            geoJson: {
                id: feature.featureId,
                type: 'Feature',
                properties: incidentProperties,
            },
            clickedPoint: {
                latitude: event.latLng.lat(),
                longitude: event.latLng.lng(),
            },
            roadway: '',
            description: incidentProperties.description,
            trafficIncidentType: incidentProperties.kind,
            affectedLanes: undefined,
            startTime: incidentProperties.startTime,
            endTime: incidentProperties.endTime,
            source: incidentProperties.source,
        } : undefined;

        onTrafficIncidentSelected?.(incident);
    };

    // using a ref makes sure that we have the latest props when clearing out
    // other selected events
    const clearOtherEventSelectionsRef = React.useRef();
    clearOtherEventSelectionsRef.current = (eventType) => {
        console.log(props.selectedCyclone, props.selectedEarthquake, props.selectedLightning, props.selectedStorm, props.selectedVolcano, props.selectedFire, props.selectedWildfire);
        if (eventType !== "cyclone") props.selectedCyclone && props.onCycloneSelected && props.onCycloneSelected(undefined);
        if (eventType !== "earthquake") props.selectedEarthquake && props.onEarthquakeSelected && props.onEarthquakeSelected(undefined);
        if (eventType !== "lightning") props.selectedLightning && props.onLightningSelected && props.onLightningSelected(undefined);
        if (eventType !== "powerOutage") props.selectedPowerOutage && props.onPowerOutageSelected && props.onPowerOutageSelected(undefined);
        if (eventType !== "storm") props.selectedStorm && props.onStormSelected && props.onStormSelected(undefined);
        if (eventType !== "stormReport") props.selectedStormReport && props.onStormReportSelected && props.onStormReportSelected(undefined);
        if (eventType !== "volcano") props.selectedVolcano && props.onVolcanoSelected && props.onVolcanoSelected(undefined);
        if (eventType !== "fire") props.selectedFire && props.onFireSelected && props.onFireSelected(undefined);
        if (eventType !== "wildfire") props.selectedWildfire && props.onWildfireSelected && props.onWildfireSelected(undefined);
    };
    const clearOtherEventSelections = (eventType) => {
        clearOtherEventSelectionsRef.current(eventType);
    };

    // effect to render cyclone markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.cyclones === undefined || props.cyclones.length === 0) {
            return;
        }

        mapManagerV2.setupCyclones(
            props.cyclones || [],
            props.isCyclonesVisible,
            props.selectedCyclone,
            props.showModelForecastTracksForCycloneId,
            (event, cyclone) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onCycloneSelected && props.onCycloneSelected(cyclone);
                clearOtherEventSelections("cyclone");
            }
        );
    }, [map, maps, props.cyclones, props.isCyclonesVisible, props.selectedCyclone, props.showModelForecastTracksForCycloneId]);

    // effect to render earthquake markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.earthquakes === undefined || props.earthquakes.length === 0) {
            return;
        }

        mapManagerV2.setupEarthquakes(
            props.earthquakes || [],
            props.isEarthquakesVisible,
            props.selectedEarthquake,
            (event, earthquake) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onEarthquakeSelected && props.onEarthquakeSelected(earthquake);
                clearOtherEventSelections("earthquake");
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );

        if (props.isEarthquakesVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.earthquakes, props.isEarthquakesVisible, props.selectedEarthquake]);

    const [lightningInterval, setLightningInterval] = React.useState();

    // effect to render lightning markers
    React.useEffect(() => {
        if (!map || !maps) return;

        // TODO: could skip calling again if the lightning is the same length as before
        // because an existing strike won't ever be updated so data can only be changed
        // by having a different number of items in the array
        if (props.lightning === undefined || props.lightning.length === 0) {
            return;
        }

        if (props.isLightningVisible && !lightningInterval) {
            // set up interval to update lightning periodically
            const updateIntervalInMinutes = 3;

            setLightningInterval(setInterval(() => {
                mapManagerV2.updateLightningColors();
            }, updateIntervalInMinutes * 60 * 1000));

            mapManagerV2.updateLightningColors();
        }

        if (!props.isLightningVisible && lightningInterval) {
            clearInterval(lightningInterval);
        }

        mapManagerV2.setupLightning(
            props.lightning,
            props.isLightningVisible,
            props.selectedLightning,
            (event, lightning) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onLightningSelected && props.onLightningSelected(lightning);
                clearOtherEventSelections("lightning");
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            },
        );
        if (props.isLightningVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.lightning?.length, props.isLightningVisible, props.selectedLightning?.id]);

    // effect to render power outage markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.powerOutages === undefined || props.powerOutages.length === 0) {
            return;
        }

        mapManagerV2.setupPowerOutages(
            props.powerOutages || [],
            props.isPowerOutagesVisible,
            props.selectedPowerOutage,
            (event, powerOutage) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onPowerOutageSelected && props.onPowerOutageSelected(powerOutage);
                clearOtherEventSelections("powerOutage");
            }
        );
        if (props.isPowerOutagesVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.powerOutages, props.isPowerOutagesVisible, props.selectedPowerOutage?.id]);

    // effect to render storms
    React.useEffect(() => {
        if (!map || !maps) return;

        mapManagerV2.setupStorms(
            props.storms || [],
            props.isStormsVisible,
            props.selectedStorm,
            (event, storm) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onStormSelected && props.onStormSelected(storm);
                clearOtherEventSelections("storm");
            }
        );
    }, [map, maps, props.storms, props.isStormsVisible, props.selectedStorm]);

    // effect to render storm reports
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.stormReports === undefined || props.stormReports.length === 0) {
            return;
        }

        mapManagerV2.setupStormReports(
            props.stormReports || [],
            props.isStormReportsVisible,
            props.selectedStormReport,
            (event, stormReport) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onStormReportSelected && props.onStormReportSelected(stormReport);
                clearOtherEventSelections("stormReport");
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            },
        );
        if (props.isStormReportsVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.stormReports, props.isStormReportsVisible]);

    // effect to render volcano markers
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.volcanoes === undefined || props.volcanoes.length === 0) {
            return;
        }

        mapManagerV2.setupVolcanoes(
            props.volcanoes || [],
            props.isVolcanoesVisible,
            props.selectedVolcano,
            (event, volcano) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onVolcanoSelected && props.onVolcanoSelected(volcano);
                clearOtherEventSelections("volcano");
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
        if (props.isVolcanoesVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.volcanoes, props.isVolcanoesVisible, props.selectedVolcano]);

    // effect to render fires
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.fires === undefined || props.fires.length === 0) {
            return;
        }

        mapManagerV2.setupFires(
            props.fires,
            props.isFiresVisible,
            props.selectedFire,
            (event, fire) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onFireSelected && props.onFireSelected(fire);
                clearOtherEventSelections("fire");
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
        if (props.isFiresVisible) {
            setZIndexForLayer(map, 4, props.showEventMarkersAboveLabels ? eventsMarkersZIndexAboveLabels : eventsMarkersZIndexBelowLabels);
        }
    }, [map, maps, props.fires, props.isFiresVisible, props.selectedFire?.id]);

    // effect to render wildfires
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.wildfires === undefined || props.wildfires.length === 0) {
            return;
        }

        mapManagerV2.setupWildfirePerimeters(
            props.wildfires || [],
            props.isWildfiresVisible,
            props.selectedWildfire,
            (event, wildfire) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onWildfireSelected && props.onWildfireSelected(wildfire);
                clearOtherEventSelections("wildfire");
            }
        );

        if (props.isWildfiresVisible) {
            // hack to makes the polygons show UNDER the place labels map layer
            setZIndexForLayer(map, 2, wildfireZIndex);
        }
    }, [map, maps, props.wildfires, props.selectedWildfire, props.isWildfiresVisible]);

    // effect to render road Status
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.roadStatus === undefined || props.roadStatus.length === 0) {
            return;
        }

        mapManagerV2.setupRoadStatus(
            props.roadStatus || [],
            props.isRoadStatusVisible,
            props.selectedRoadStatus,
            (event, roadStatus) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onRoadStatusSelected && props.onRoadStatusSelected(roadStatus);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );

        if (props.isRoadStatusVisible) {
            // hack to makes the road status line show UNDER the place labels map layer
            setZIndexForLayer(map, 2, roadStatusZindex);
        }
    }, [map, maps, props.roadStatus, props.isRoadStatusVisible, props.selectedRoadStatus]);

    // effect to render road work
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.roadWork === undefined || props.roadWork.length === 0) {
            return;
        }

        mapManagerV2.setupRoadWork(
            props.roadWork || [],
            props.isRoadWorkVisible,
            props.selectedRoadWork,
            (event, roadWork) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onRoadWorkSelected && props.onRoadWorkSelected(roadWork);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.roadWork, props.isRoadWorkVisible, props.selectedRoadWork]);

    // effect to render road closures
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.roadClosures === undefined || props.roadClosures.length === 0) {
            return;
        }

        mapManagerV2.setupRoadClosures(
            props.roadClosures || [],
            props.isRoadClosuresVisible,
            props.selectedRoadClosure,
            (event, roadClosures) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onRoadClosureSelected && props.onRoadClosureSelected(roadClosures);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.roadClosures, props.isRoadClosuresVisible, props.selectedRoadClosure]);

    // effect to render special events
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.specialEvents === undefined || props.specialEvents.length === 0) {
            return;
        }

        mapManagerV2.setupSpecialEvents(
            props.specialEvents || [],
            props.isSpecialEventsVisible,
            props.selectedSpecialEvent,
            (event, specialEvent) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onSpecialEventSelected && props.onSpecialEventSelected(specialEvent);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.specialEvents, props.isSpecialEventsVisible, props.selectedSpecialEvent]);

    // effect to render traffic congestion
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.trafficCongestion === undefined || props.trafficCongestion.length === 0) {
            return;
        }

        mapManagerV2.setupTrafficCongestion(
            props.trafficCongestion || [],
            props.showTrafficCongestion,
            props.selectedTrafficCongestion,
            (event, trafficCongestion) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onTrafficCongestionSelected && props.onTrafficCongestionSelected(trafficCongestion);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.trafficCongestion, props.showTrafficCongestion, props.selectedTrafficCongestion]);

    // effect to render traffic incidents
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.trafficIncidents === undefined || props.trafficIncidents.length === 0) {
            return;
        }

        mapManagerV2.setupTrafficIncidents(
            props.trafficIncidents || [],
            props.showTrafficIncidents,
            props.selectedTrafficIncident,
            (event, trafficIncident) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onTrafficIncidentSelected && props.onTrafficIncidentSelected(trafficIncident);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.trafficIncidents, props.showTrafficIncidents, props.selectedTrafficIncident]);

    // effect to render truck warnings
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.truckWarnings === undefined || props.truckWarnings.length === 0) {
            return;
        }

        mapManagerV2.setupTruckWarnings(
            props.truckWarnings || [],
            props.isTruckWarningsVisible,
            props.selectedTruckWarning,
            (event, truckWarning) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onTruckWarningSelected && props.onTruckWarningSelected(truckWarning);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            }
        );
    }, [map, maps, props.truckWarnings, props.isTruckWarningsVisible, props.selectedTruckWarning]);

    // not showing weather stations with driving conditions for now
    // effect to render weather stations
    React.useEffect(() => {
        if (!map || !maps) return;

        if (props.weatherStations === undefined || props.weatherStations.length === 0) {
            return;
        }

        mapManagerV2.setupWeatherStations(
            props.weatherStations || [],
            props.isRoadStatusVisible,
            props.selectedWeatherStation,
            (event, weatherStation) => {
                setMapObjectClicked(true);
                event.domEvent.stopPropagation();
                props.onWeatherStationSelected && props.onWeatherStationSelected(weatherStation);
            }, (event, cluster, map) => {
                event.domEvent.stopPropagation();
                if (cluster.bounds !== undefined) {
                    map.fitBounds(cluster.bounds);
                }
            },
        );
    }, [map, maps, props.weatherStations, props.isRoadStatusVisible, props.selectedWeatherStation]);

    // render the geojsonLayers that are passed in
    // this logic generically renders a geojson object with some metadata that is passed in
    // the id is used to prevent re-rendering, style and onClick are self explanatory
    // 
    // the idea is that you could pass in arbitrary layers like a GeoJSON of hospital locations
    // and this would be a generic way to render it on the map with custom visuals/onClick
    // behavior
    //
    // geojsonLayers = the geojson data itself with metadata that you pass in
    // geojsonLayerDatas = the google.maps.Data objects that show the geojson data on the map
    React.useEffect(() => {
        if (!map || !maps) return;

        // get the currently showing layer ids
        const oldGeojsonLayerIds = Object.keys(geojsonLayerDatas);
        // get the new layer ids
        const newGeojsonLayerIds = geojsonLayers.map(l => l.id);

        // remove any data from the map that is not in the new list
        for (const id of oldGeojsonLayerIds) {
            const data = geojsonLayerDatas[id];
            if (!newGeojsonLayerIds.includes(id)) {
                data.setMap(null);
            }
        }

        const datas = {};
        for (const layer of geojsonLayers) {
            // if the id is the same, we re-use the same Data instance
            if (oldGeojsonLayerIds.includes(layer.id)) {
                datas[layer.id] = geojsonLayerDatas[layer.id];
            } else {
                // otherwise, create a new one and store
                const data = new maps.Data();
                data.addGeoJson(layer.geojsonData);
                data.setStyle(layer.style);
                data.addListener('click', layer.onClick);
                data.setMap(map);

                datas[layer.id] = data;
            }
        }

        // HACK: lets us put the alerts in the GovernmentalAlertsPage below the map labels
        if (Object.keys(datas).length > 0 && geojsonLayers[0].geojsonData?.properties?.type === 'governmental_alert') {
            setZIndexForLayer(map, 2, alertsZIndex);
        }

        setGeojsonLayerDatas(datas);
    }, [map, maps, geojsonLayers]);

    React.useEffect(() => {
        mapManager.setToken(portalToken);
    }, [portalToken]);

    React.useEffect(() => {
        mapManager.setTileLayerLoaded(onTileLayerLoaded);
    }, [onTileLayerLoaded]);

    React.useEffect(() => {
        if (!map || !maps) return;

        mapManager.setDesiredTileLayer(desiredTileLayer);
        setZIndexForLayer(map, 1, weatherTilesZIndex);
    }, [map, maps, desiredTileLayer]);

    React.useEffect(() => {
        if (!map || !maps) return;

        mapManager.maskType = maskType;
        mapManager.invalidateLayers();
    }, [map, maps, maskType]);

    const [hasFitBounds, setFitBounds] = React.useState(false);

    // effect to fit bounds to initialBounds
    React.useEffect(() => {
        if (!map || !maps) return;

        // if there was an initial center passed, we're going to prefer it
        // to the bounds based off the logic further down where it is used first
        // so we'll skip fitting to the bounds if its present
        if (props.initialCenter !== undefined) {
            setFitBounds(true);
            return;
        }
        if (props.initialBounds === undefined) {
            return;
        }

        if (!hasFitBounds) {
            map.fitBounds(
                new maps.LatLngBounds(props.initialBounds.sw, props.initialBounds.ne),
                props.padding
            );
        }

        setFitBounds(true);
        // TODO: why does adding props.padding into the deps array cause an infinite render?
    }, [map, maps, props.initialCenter, props.initialBounds]);

    React.useEffect(() => {
        prevSelectedCityIdRef.current = props.selectedCity?.id;
    });

    React.useEffect(() => {
        prevTimeframe.current = props.timeframe?.label;
    });

    React.useEffect(() => {
        prevSelectedTimelineViewRef.current = props.selectedTimelineView;
    });

    React.useEffect(() => {
        prevIsLocationsVisibleRef.current = props.isLocationsVisible;
    });

    React.useEffect(() => {
        prevSelectedVehicleIdRef.current = props.selectedVehicle?.id;
    });

    let center;
    if (props.initialCenter) {
        center = props.initialCenter;
    } else if (props.initialBounds) {
        center = {
            lat: (props.initialBounds.sw.lat + props.initialBounds.ne.lat) / 2,
            lng: (props.initialBounds.sw.lng + props.initialBounds.ne.lng) / 2
        };
    } else if (props.selectedCity) {
        center = getLatLngFromLocation(props.selectedCity);
    } else {
        console.warn("!!! No way to get an initial center for RouteAndMarkerMap!");
        center = { lat: 39.0119, lng: -98.4842 }; // use middle of Kansas
    }

    // only pass the center and zoom to gmaps on initial render - cuts down on perf issues
    const defaultCenterRef = React.useRef();
    if (defaultCenterRef.current === undefined) {
        defaultCenterRef.current = center;
    }

    const defaultZoomRef = React.useRef();
    if (defaultZoomRef.current === undefined) {
        defaultZoomRef.current = props.zoomLevel;
    }

    const previousForceCenterRef = React.useRef();
    const forceCenter = props.forceCenter;

    if (forceCenter !== undefined && forceCenter !== previousForceCenterRef.current) {
        console.log("forcing pan to ", forceCenter);
        previousForceCenterRef.current = forceCenter;
        map.panTo(forceCenter);
    }

    const previousForceZoomRef = React.useRef();
    const forceZoom = props.forceZoom;

    if (forceZoom !== previousForceZoomRef.current) {
        if (forceZoom !== undefined) {
            console.log("forcing zoom to ", forceZoom);
            previousForceZoomRef.current = forceZoom;
            map.setZoom(forceZoom);
            props.onForceZoomCompleted?.();
        } else {
            previousForceZoomRef.current = undefined;
        }
    }

    const previousForceBoundsRef = React.useRef();
    const forceBounds = props.forceBounds;

    if (forceBounds !== undefined && forceBounds !== previousForceBoundsRef.current) {
        console.log("forcing bounds to ", forceBounds);
        previousForceBoundsRef.current = forceBounds;
        map.fitBounds(new maps.LatLngBounds(forceBounds.southwest, forceBounds.northeast), props.padding);
    }

    const handleMapClicked = (latLng) => {
        map.panTo(latLng);
        onMapClicked?.(latLng);
    };

    let mapOptions = { backgroundColor: 'black', mapTypeControl: false, fullscreenControl: false, zoomControl: props.zoomControl, zoomControlOptions: props.zoomControlOptions };
    if (isRouteReport) {
        mapOptions = { backgroundColor: 'black', zoomControl: false, fullscreenControl: false };
    }
    if (props.mapTypeControl) {
        mapOptions = { mapTypeControl: props.mapTypeControl, mapTypeControlOptions: props.mapTypeControlOptions, fullscreenControl: false, zoomControl: props.zoomControl, zoomControlOptions: props.zoomControlOptions };
    }
    // required to set a mapId in order for AdvancedMarkerElements to work
    mapOptions.mapId = 'DEMO_MAP_ID';
    mapOptions.minZoom = props.minZoom;
    mapOptions.maxZoom = props.maxZoom;

    return (
        <div className={'route-map'} style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={bootstrapURLKeys}
                center={defaultCenterRef.current}
                defaultZoom={defaultZoomRef.current}
                options={mapOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                    mapManager.mapsAPILoaded(map, maps);
                    mapManagerV2.mapsAPILoaded(map);
                    setMap(map);
                    setMaps(maps);
                }}
                onClick={(event) => {
                    if (mapObjectClicked) {
                        setMapObjectClicked(false);
                        return;
                    }
                    if (mapObjectClicked === false) {
                        handleMapClicked(new maps.LatLng(event.lat, event.lng));
                    }
                    if (routeReportMode === 'navigation') {
                        onTrackingModeToggled?.(false);
                    }
                }}
                onChange={(value) => {
                    onBoundsChanged?.(value.bounds);
                }}
            >
                {props.children}
            </GoogleMapReact>
        </div>
    );
};

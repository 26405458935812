import { RatingKey } from './RatingKey';
import { ImpactLevel, RouteData, RoutesViewState, WeatherFlag } from './routes';
import { PlacesViewState } from './PlacesView';
import { DashboardViewState } from './DashboardView';
import moment from 'moment';
import { ImpactMapType } from './MapType';
import { capitalize } from '@mui/material';
import TimeAgo from 'javascript-time-ago';

export interface LoadableResultMetadata {
    success: boolean;
    loading: boolean;
    error?: Error;
}

export class LoadableResult<T> {
    value?: T;

    success: boolean;
    loading: boolean;
    error?: Error;

    loadedAt?: Date;
    cacheTimeSeconds?: number;

    abortController: AbortController;

    constructor(cacheTimeSeconds?: number) {
        this.value = undefined;

        this.success = false;
        this.loading = false;
        this.error = undefined;

        this.loadedAt = undefined;
        this.cacheTimeSeconds = cacheTimeSeconds;

        this.abortController = new AbortController();
    }

    setLoading() {
        this.success = false;
        this.loading = true;
        this.error = undefined;

        this.loadedAt = undefined;
    }

    setSuccess(value: T) {
        this.value = value;

        this.success = true;
        this.loading = false;
        this.error = undefined;

        this.loadedAt = new Date();
    }

    setError(error: Error) {
        this.success = false;
        this.loading = false;
        this.error = error;

        this.loadedAt = undefined;
    }

    abort() {
        this.abortController?.abort();
        this.abortController = new AbortController();
    }

    isCacheValid() {
        if (this.cacheTimeSeconds === undefined || this.loadedAt === undefined) {
            return false;
        }
        return this.loadedAt.getTime() > new Date().getTime() - this.cacheTimeSeconds * 1000;
    }

    copy(): LoadableResult<T> {
        const copiedResult = new LoadableResult<T>();
        copiedResult.value = this.value;

        copiedResult.success = this.success;
        copiedResult.loading = this.loading;
        copiedResult.error = this.error;

        copiedResult.loadedAt = this.loadedAt;
        copiedResult.cacheTimeSeconds = this.cacheTimeSeconds;

        copiedResult.abortController = this.abortController;

        return copiedResult;
    }
}

export interface WeatherConditions {
    time: Date;
    temperature: number;
    rainfall?: number;
    snowfall?: number;
    rainAccumulation?: number;
    snowAccumulation?: number;
    totalPrecipitation?: number;
    windSpeed?: number;
    windGust?: number;
    heatIndex?: number;
    visibility?: number;
    lightningProbability?: number;
    windGustProbability?: number;
}

export interface ImpactTypeDetailed {
    impactLevel: ImpactLevel;
    impactType: string;
    impactValue?: number;
}

export interface ImpactSummary {
    label: string;
    value: number;
    impactLevel: ImpactLevel;
    impactTypes: RatingKey[];
    impactTypesDetailed: ImpactTypeDetailed[];
    peakImpactTimeMoment: moment.Moment;
    weatherFlags: WeatherFlag[];
}

export interface SubhourlyImpactSummary {
    tags: ImpactTag[];
    tagPriority: number;
    impactLevel: ImpactLevel;
    lastUpdatedAt: Date;
    sourceRunTime?: Date;
    source?: string;
}

export interface BaseLocationData {
    id?: number;
    assetType?: string,
    zip?: string;
    name: string;
    latitude: number;
    longitude: number;
    timezone?: string;
    impactSummaries: ImpactSummary[];
    impactSummariesUpdatedAt?: Date;
    impactSummariesRatingRunTime?: Date;
    subhourlyImpactSummary?: SubhourlyImpactSummary;
    extendedImpactSummary?: SubhourlyImpactSummary;
    userMetadata?: { [key: string]: any };
}

export interface BasicLatLng {
    lat: number;
    lng: number;
}

export interface LatLngBounds {
    northeast: BasicLatLng;
    southwest: BasicLatLng;
}

export function getLatLngFromLocation(location: BaseLocationData) {
    return { lat: location.latitude, lng: location.longitude };
}

export interface ViewportData {
    // viewport for location
    neLatitude: number;
    neLongitude: number;
    swLatitude: number;
    swLongitude: number;
}

function degToRad(degree: number) {
    return degree * Math.PI / 180;
}

function radToDegree(radian: number) {
    return radian * (180 / Math.PI);
}

export function getCenterOfViewport(viewport: ViewportData) {

    // Longitude difference
    const dLng = degToRad(viewport.swLongitude - viewport.neLongitude);

    // Convert to radians
    const lat1 = degToRad(viewport.neLatitude);
    const lat2 = degToRad(viewport.swLatitude);
    const lng1 = degToRad(viewport.neLongitude);

    const bX = Math.cos(lat2) * Math.cos(dLng);
    const bY = Math.cos(lat2) * Math.sin(dLng);
    const centerLat = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
    const centerLng = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

    return { lat: radToDegree(centerLat), lng: radToDegree(centerLng) };
}

export function isLatLngInViewport(latLng: BasicLatLng, viewport: ViewportData) {
    const lat = latLng.lat;
    const lng = latLng.lng;
    const northLat = viewport.neLatitude;
    const eastLng = viewport.neLongitude;
    const southLat = viewport.swLatitude;
    const westLng = viewport.swLongitude;

    let insideLat = lat <= northLat && lat >= southLat;
    let insideLng = false;

    // true if viewport overlaps the antimeridian
    if (eastLng < westLng) {
        insideLng = (lng >= -180 && lng <= eastLng) || (lng <= 180 && lng >= westLng);
    } else {
        insideLng = lng >= westLng && lng <= eastLng;
    }

    return insideLat && insideLng;
}

export function haversineDistance(point1: BasicLatLng, point2: BasicLatLng, isMiles: boolean) {
    let dLat = degToRad(point2.lat - point1.lat);
    let dLon = degToRad(point2.lng - point1.lng);
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degToRad(point1.lat)) * Math.cos(degToRad(point2.lat)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = 6371 * c; // km

    if (isMiles)
        d /= 1.60934;

    return d;
}

export interface NeedsGeocodingData {
    needsGeocoding: boolean;
}

export type LocationData = BaseLocationData & Partial<ViewportData> & NeedsGeocodingData;

export function fullViewportData(data: Partial<ViewportData>): ViewportData | undefined {
    if (data.neLatitude !== undefined &&
        data.neLongitude !== undefined &&
        data.swLatitude !== undefined &&
        data.swLongitude !== undefined) {
        return {
            neLatitude: data.neLatitude,
            neLongitude: data.neLongitude,
            swLatitude: data.swLatitude,
            swLongitude: data.swLongitude,
        };
    }
    return undefined;
}

export const locationsHaveEqualCenters = (a: BaseLocationData, b: BaseLocationData) => {
    return Math.abs(a.latitude - b.latitude) < 0.000000001 && Math.abs(a.longitude - b.longitude) < 0.000000001;
};

export interface ImpactTag {
    id?: string;
    source: string;
    text: string;
    value?: number;
    variable?: string;
    impactLevel: ImpactLevel;
    event?: string;
    description?: string;
    color?: string;
    effective?: string;
    expires?: string;
}

export type VehicleType = 'car' | 'truck';

export interface VehicleImpactTag extends ImpactTag {
    // moved to more general ImpactTag
}

export interface VehicleInsightTag extends VehicleImpactTag {
    reason: string;
}

export interface VehicleImpact {
    tags: VehicleImpactTag[];
    tagPriority: number;
    overallImpactLevel: ImpactLevel;
    lastUpdatedAt: Date;
}

export interface VehicleInsight {
    tags: VehicleInsightTag[];
}

export interface VehicleCurrentConditions {
    temperature: {
        value: number;
        label: string;
        color: string;
    };

    precipitation?: {
        type: string;
        value: number;
        label: string;
        units: string;
        color: string;
    };

    visibility: {
        value: number;
        label: string;
        color: string;
    };

    wind: {
        value: number;
        directionDegrees: number;
        directionCardinal: string;
        maxGust: number;
        color: string;
    };

    roadRisk: {
        value: number;
        label: string;
        color: string;
    };

    // can be missing if no vehicle bearing is available
    tippingIndex?: {
        value: number;
        label: string;
        color: string;
    };
}

export interface VehicleMessage {
    id: number;
    vehicleId: number;
    vehicleExternalId?: string;
    vehicleName?: string;
    message: string;
    urgent: boolean
    source: string;
    status: string;
    sentAt: Date;
    latitude: number;
    longitude: number;
    error: string;
}

export interface VehicleTrackingData {
    id: string;
    assetType: string;
    externalId: string;
    externalDriverId?: string;
    externalGroupIds: string[];
    externalUpdatedAt?: Date;
    name?: string;
    latitude?: number;
    longitude?: number;
    bearing?: number;
    speed?: number;
    source: string;
    routeId: number;
    currentImpact?: VehicleImpact;
    currentInsight?: VehicleInsight;
    currentConditions?: VehicleCurrentConditions;
    upcomingImpact?: VehicleImpact;
    notificationsEnabled?: boolean;
}

export type DriverNotificationType = 'impact' | 'alert';
export type DriverNotificationFrequency = 'change' | 'interval';

export interface DriverNotificationThreshold {
    id: string | undefined;
    index: RatingKey;
    lowerBound: number;
    upperBound: number;
}

export interface DriverNotification {
    id: number | undefined;
    name: string;
    status: string;
    notificationType: DriverNotificationType;
    frequency: DriverNotificationFrequency;
    interval: number;
    windowSize: number;
    thresholds: DriverNotificationThreshold[];
    alerts: string[];
    template: string;
    urgent: boolean;
}

export interface WeatherData {
    hourly: WeatherConditions[];
    subhourly?: WeatherConditions[];
    city: LocationData;
    alerts: AlertData[];
}

export interface RatingsByIndex {
    road: HourRatingsData[];
    power: HourRatingsData[];
    disruption: HourRatingsData[];
    flood: HourRatingsData[];
    life_property: HourRatingsData[];
    wildfire_spread: HourRatingsData[];
    wildfire_conditions: HourRatingsData[];
}

export interface LocationInfo {
    latitude: number;
    longitude: number;
    timezone?: string;
}

export type RatingsDataWithLocationInfo = RatingsByIndex & LocationInfo;

export interface AlertDetails {
    type: string;
    name: string;
    emergency: boolean;
    color: string;
    body: string;
    bodyFull: string;
}

export interface AlertTimestamps {
    issued: Date;
    begins: Date;
    expires: Date;
}

export interface AlertData {
    details: AlertDetails;

    active: boolean;

    id: string;

    timestamps: AlertTimestamps;

    geojsonFeature: any;
}

export interface TrafficViewState {
    selectedRoadStatus?: RoadStatusData;
    selectedRoadWork?: RoadWorkData;
    selectedRoadClosure?: RoadClosureData;
    selectedSpecialEvent?: SpecialEventData;
    selectedTrafficCongestion?: TrafficCongestionData;
    selectedTrafficIncident?: TrafficIncidentData;
    selectedTruckWarning?: TruckWarningData;
    selectedWeatherStation?: WeatherStationData;

    isRoadStatusVisible?: boolean;
    isRoadWorkVisible?: boolean;
    isRoadClosuresVisible?: boolean;
    isSpecialEventsVisible?: boolean;
    isTrafficCongestionVisible?: boolean;
    isTrafficIncidentsVisible?: boolean;
    isTruckWarningsVisible?: boolean;
    isWeatherStationsVisible?: boolean;

    roadStatus?: RoadStatusData[];
    roadWork?: RoadWorkData[];
    roadClosures?: RoadClosureData[];
    specialEvents?: SpecialEventData[];
    trafficCongestion?: TrafficCongestionData[];
    trafficIncidents?: TrafficIncidentData[];
    truckWarnings?: TruckWarningData[];
    weatherStations?: WeatherStationData[];

    isFetching: boolean;
    roadConditionsExpiryTime?: number;
}

export type LightningData = EventData & {
    energy: number;
    qualityFlag: boolean;
    area: number;
    detectedTime: Date;
}

export interface PowerUtilityData {
    name: string;
    metersOut: number;
    metersServed: number;
}

export type PowerOutageData = EventData & {
    county: string;
    state: string;
    stateAbbr: string;
    percentOut: number;
    metersOut: number;
    metersServed: number;
    utilitiesTracked: PowerUtilityData[];
    fill: string;
    fillOpacity: number;
}

export type EventType = 'cyclone' | 'volcano' | 'earthquake' | 'storm' | 'fire' | 'wildfire' | 'lightning' | 'power_outage' | 'storm_report' | 'road_status' | 'weather_station';

export interface EventData {
    id: string;
    type: EventType;
    geoJson: GeoJSON.Feature;

    // populated on click of lines/polygons
    clickedPoint?: { latitude: number, longitude: number };
}

export function getPointForEvent(event: EventData) {
    try {
        if (event.geoJson.geometry.type === 'Point') {
            const pointGeometry = event.geoJson.geometry as GeoJSON.Point;
            return {
                latitude: pointGeometry.coordinates[1],
                longitude: pointGeometry.coordinates[0],
            };
        } else if (event.geoJson.geometry.type === 'LineString') {
            const lineGeometry = event.geoJson.geometry as GeoJSON.LineString;
            const midPoint = lineGeometry.coordinates[Math.floor(lineGeometry.coordinates.length / 2)];
            return {
                latitude: midPoint[1],
                longitude: midPoint[0],
            };
        } else if (event.geoJson.geometry.type === 'MultiLineString') {
            const lineGeometry = event.geoJson.geometry as GeoJSON.MultiLineString;
            const firstLine = lineGeometry.coordinates[0];
            const midPointOfFirstLine = firstLine[Math.floor(firstLine.length / 2)];
            return {
                latitude: midPointOfFirstLine[1],
                longitude: midPointOfFirstLine[0],
            };
        }
    } catch (e) {
        console.log(e);
    }
    // TODO: add Polygon logic to pick center
    return {
        latitude: 0,
        longitude: 0,
    };
}

export function eventIsPolygon(event: EventData): boolean {
    return false;
}

export type CycloneFeature = 'storm_track_point' | 'storm_track' | 'storm_cone_of_uncertainty' | 'storm_danger_swath' | 'storm_track_alternate' | 'storm_track_point_alternate' | 'disturbance_point' | 'disturbance_track' | 'disturbance_area';

export type CycloneData = EventData & {
    cycloneId: string;
    name: string;
    feature: CycloneFeature;
    hasModelForecastTracks: boolean;
    description?: string;
}

export type CyclonePointData = CycloneData & {
    category?: string;
    windMPH?: number;
    gustMPH?: number;
    expectedArrivalTime: Date;
    modelName: string;
    twoDayFormationCategory?: string;
}

export type CycloneTrackData = CycloneData & {
    modelName: string;
}
export type CycloneConeData = CycloneData & {
    description?: string;
}
export type CycloneSwathData = CycloneData & {
    description?: string;
}

export type EarthquakeData = EventData & {
    earthquakeId: string;
    feature: string;
    magnitude: number;
    status: string;
    detectedTime: Date;
    hasShakemap: boolean;
    latitude?: number;
    longitude?: number;
}

export type EarthquakeEpicenterData = EarthquakeData

export type EarthquakeShakemapData = EarthquakeData & {
    value: number;
    shaking: string;
    description: string;
}

export type StormData = EventData & {
    stormType: string;
    maxSnowAccumulation: number;
    maxFrozenRainAccumulation: number;
    maxRainAccumulation: number;
    maxGust: number;
    avgPeakRoadIndex: number;
    avgPeakFloodIndex: number;
    avgPeakDisruptionIndex: number;
    avgPeakWindGust: number;
    affectedCountries: string;
    weatherFlags: string[];
    startTime: Date;
    endTime: Date;
    briefing: string;
}

export type StormReportData = EventData & {
    reportType: string;
    magnitude: number;
    units: string;
    description: string;
    location: string;
    nwsOffice: string;
    markerColor: string;
    updatedTime: Date;
}

// TODO: if this stick as the preferred visual, we should
// move these colors/categories to the pipeline and add to
// the geojson data so that its easily available for API users
export const categoryToColorMapping: { [key: string]: string } = {
    'Tornado': '#DF4E00',
    'Winter': '#FF69B4',
    'Hail': '#B52AFF',
    'Tropical': '#40E0D0',
    'Wind': '#FFFFFF',
    'Flooding': '#1E90FF',
    'Other': '#A9A9A9',
};

export function getCategoryForReportType(reportType: string): string {
    switch (reportType) {
        case 'Tornado':
        case 'Funnel Cloud':
        case 'Landspout':
        case 'Waterspout':
            return 'Tornado';

        case 'Hail':
        case 'Marine Hail':
            return 'Hail';

        case 'Non-Thunderstorm Wind Gust':
        case 'Thunderstorm Wind Gust':
        case 'Downburst':
        case 'High Sust Winds':
            return 'Wind';

        case 'Avalanche':
        case 'Blizzard':
        case 'Snow Squall':
        case 'Snow/Ice Dmg':
            return 'Winter';

        case 'Coastal Flood':
        case 'Debris Flow':
        case 'Flash Flood':
        case 'Flood':
        case 'Ice Jam':
        case 'Ice Jam Flooding':
        case 'Landslide':
            return 'Flooding';

        case 'Storm Surge':
        case 'Tropical Cyclone':
            return 'Tropical';

        case 'Blowing Dust':
        case 'Drought':
        case 'Dust Storm':
        case 'Misc Mrn/Srf Hzd':
        case 'Sneaker Wave':
        case 'Vog':
        case 'Volcanic Ash':
        case 'Wildfire':
            return 'Other';

        default:
            console.warn("Unhandled storm report type:", reportType);
            return 'Other';
    }
}

export function getColorForReportType(reportType: string): string {
    const category = getCategoryForReportType(reportType);
    return categoryToColorMapping[category] ?? '#FF00FF';
}

export type VolcanoData = EventData & {
    name: string;
    description: string;
    activityType: string;
    latestActivity: string;
    updatedTime: Date;
}

export type FireData = EventData & {
    dateReported?: Date;
    lastUpdated?: Date;
    confidence?: number;
}

export type WildfireData = EventData & {
    name?: string;
    dateReported?: Date;
    lastUpdated?: Date;
    sizeAcres?: number; // 
    complexity?: string; // range 1-6
    behavior?: string; // mininmal, moderate, active, extreme
    percentContained?: number;
    percentSuppressed?: number;
    confidence?: number;
}

export function mphToKnots(speedInMPH: number, roundToInt: boolean = false) {
    let speedInKnots = speedInMPH * 0.868976;
    if (roundToInt) {
        speedInKnots = Math.round(speedInKnots);
    }
    return speedInKnots;
}

export type RoadStatusData = EventData & {
    roadway: string;
    description: string;
    status: string;
    color: string;
    start: string;
    end: string;
    strokeColor: string;
}

export type RoadWorkData = EventData & {
    roadway: string;
    title?: string;
    description?: string;
    severity?: string;
    affectedLanes?: string;
    startTime?: Date;
    endTime?: Date;
}

export type RoadClosureData = EventData & {
    roadway: string;
    description?: string;
    reason?: string;
    affectedLanes?: string;
    startTime?: Date;
    endTime?: Date;
}

export type SpecialEventData = EventData & {
    title?: string;
    description?: string;
    specialEventType?: string;
    location?: string;
    startTime?: Date;
    endTime?: Date;
}

export type TrafficCongestionData = EventData & {
    roadway: string;
    description?: string;
    trafficCongestionType?: string;
    reason?: string;
    startTime?: Date;
    endTime?: Date;
}

export type TrafficIncidentData = EventData & {
    roadway: string;
    description?: string;
    trafficIncidentType?: string;
    affectedLanes?: string;
    startTime?: Date;
    endTime?: Date;
    source?: string;
}

export type TruckWarningData = EventData & {
    title?: string;
    description?: string;
    startTime?: Date;
    endTime?: Date;
}

export type WeatherStationData = EventData & {
    roadway?: string;
    description?: string;
    status?: string;
    temperature?: number;
    precipitation?: string;
    windSpeed?: number;
    windSpeedDirection?: string;
    windGust?: number;
    windGustDirection?: string;
}

export function getRoadConditionColorForPrecipitationType(precipitationType: any) {
    if (precipitationType === undefined || precipitationType === null || typeof precipitationType !== 'string') return '#9c9c9c';
    if (precipitationType.toLowerCase().startsWith('no') || ['dry', 'clear', 'normal', 'good', 'n/a'].some(p => precipitationType.toLowerCase().includes(p))) return '#007e01'; // taking no precip as dry/normal
    if (['rain', 'precip', 'yes', 'wet', 'damp', 'moist'].some(p => precipitationType.toLowerCase().includes(p))) return '#3f6fbc';
    if (precipitationType.toLowerCase().includes('snow')) return '#ffffff';
    if (precipitationType.toLowerCase().includes('ice')) return '#ea81f3';
    if (['fog', 'vis'].some(p => precipitationType.toLowerCase().includes(p))) return '#7c1e19';
    if (['severe', 'rough', 'flood', 'flooding'].some(p => precipitationType.toLowerCase().includes(p))) return '#ff2200';
    if (precipitationType.toLowerCase().includes('closed')) return '#8f0c91';
    return '#9c9c9c';
}

export function knotsToMph(speedInKnots: number, roundToInt: boolean = false) {
    let speedInMph = speedInKnots * 1.15078;
    if (roundToInt) {
        speedInMph = Math.round(speedInMph);
    }
    return speedInMph;
}

export function getStormCategoryForWindSpeedInKnots(windSpeedKnots: number) {
    if (windSpeedKnots <= 33) {
        return "Tropical Depression";
    } else if (windSpeedKnots > 34 && windSpeedKnots <= 63) {
        return "Tropical Storm";
    } else if (windSpeedKnots > 64 && windSpeedKnots <= 82) {
        return "Category 1";
    } else if (windSpeedKnots >= 83 && windSpeedKnots <= 95) {
        return "Category 2";
    } else if (windSpeedKnots >= 96 && windSpeedKnots <= 112) {
        return "Category 3";
    } else if (windSpeedKnots >= 113 && windSpeedKnots <= 136) {
        return "Category 4";
    } else if (windSpeedKnots >= 137) {
        return "Category 5";
    }
    return "Unknown";
}

export function getStormColorForWindSpeedInMph(windSpeedMph: number) {
    return getStormColorForWindSpeedInKnots(windSpeedMph * 0.868976);
}

export function getStormColorForWindSpeedInKnots(windSpeedKnots: number) {
    const stormCategory = getStormCategoryForWindSpeedInKnots(windSpeedKnots);
    if (stormCategory === 'Tropical Depression') return '#11EEFC';
    if (stormCategory === 'Tropical Storm') return '#7AFC00';
    if (stormCategory === 'Category 1') return '#FCFC00';
    if (stormCategory === 'Category 2') return '#FCAB00';
    if (stormCategory === 'Category 3') return '#FA2200';
    if (stormCategory === 'Category 4') return '#FC00FC';
    if (stormCategory === 'Category 5') return '#FFC2FF';
    return 'white';
}

export function getEarthquakeColorForMagnitude(magnitude: number) {
    if (magnitude < 1) return '#FFFFFF';
    if (magnitude >= 1 && magnitude < 2) return '#87CEFA';
    if (magnitude >= 2 && magnitude < 3) return '#AFEEEE';
    if (magnitude >= 3 && magnitude < 4) return '#98FB98';
    if (magnitude >= 4 && magnitude < 5) return '#ADFF2F';
    if (magnitude >= 5 && magnitude < 6) return '#FFFF00';
    if (magnitude >= 6 && magnitude < 7) return '#FFD700';
    if (magnitude >= 7 && magnitude < 8) return '#FF8C00';
    if (magnitude >= 8 && magnitude < 9) return '#FF2200';
    if (magnitude >= 9) return '#800C00';
    return '#FFFFFF';
}

export const getEventDescription = (event: any, eventType: string) => {
    let eventDescription = '';
    const timeAgo = new TimeAgo('en-US');
    if (eventType === 'cyclone') {
        const cyclone: CycloneData = event;
        let cycloneDescription = ``;
        if (cyclone.feature === 'storm_track_point' || cyclone.feature === 'storm_track_point_alternate' || cyclone.feature === 'disturbance_point') {
            const cyclonePoint = cyclone as CyclonePointData;
            if (cyclonePoint.category) {
                cycloneDescription += `<div><b>Category:</b> ${cyclonePoint.category}</div>`;
            }
            cycloneDescription += `<div><b>Expected Arrival:</b> ${cyclonePoint.expectedArrivalTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
            if (cyclonePoint.windMPH) {
                cycloneDescription += `<div><b>Wind Speed:</b> ${cyclonePoint.windMPH.toFixed(1)} mph</div>`;
            }
            if (cyclonePoint.gustMPH) {
                cycloneDescription += `<div><b>Wind Gust Speed:</b> ${cyclonePoint.gustMPH.toFixed(1)} mph</div>`;
            }
            const isBestTrack = cyclonePoint.modelName === 'NHC' || cyclonePoint.modelName === 'JTWC';
            const modelName = isBestTrack ? `Official ${cyclonePoint.modelName} Forecast` : cyclonePoint.modelName;
            cycloneDescription += `<div><b>Model:</b> ${modelName}</div>`;
            if (cyclonePoint.description) {
                cycloneDescription += `<div><b>Description:</b> ${cyclonePoint.description.replaceAll('\n', '<br/>')}</div>`;
            }
        } else if (cyclone.feature === 'storm_track' || cyclone.feature === 'storm_track_alternate' || cyclone.feature === 'disturbance_track') {
            const cycloneTrack = cyclone as CycloneTrackData;
            const isBestTrack = cycloneTrack.modelName === 'NHC' || cycloneTrack.modelName === 'JTWC';
            const modelName = isBestTrack ? `Official ${cycloneTrack.modelName} Forecast` : cycloneTrack.modelName;
            cycloneDescription += `<div><b>Model:</b> ${modelName}</div>`;
            if (cycloneTrack.description) {
                cycloneDescription += `<div><b>Description:</b> ${cycloneTrack.description.replaceAll('\n', '<br/>')}</div>`;
            }
        } else if (cyclone.feature === 'storm_cone_of_uncertainty' || cyclone.feature === 'disturbance_area') {
            const cycloneCone = cyclone as CycloneConeData;
            if (cycloneCone.description) {
                cycloneDescription += `<div><b>Description:</b><br/>${cycloneCone.description.replaceAll('\n', '<br/>')}</div>`;
            }
        } else if (cyclone.feature === 'storm_danger_swath') {
            const cycloneSwath = cyclone as CycloneSwathData;
            if (cycloneSwath.description) {
                cycloneDescription += `<div><b>Description:</b> ${cycloneSwath.description.replaceAll('\n', '<br/>')}</div>`;
            }
        }
        eventDescription = cycloneDescription;
    } else if (eventType === 'earthquake') {
        const earthquake: EarthquakeData = event;
        let earthquakeDescription = ``;
        if (earthquake.magnitude) earthquakeDescription += `<div><b>Magnitude:</b> ${earthquake.magnitude.toFixed(1)}</div>`;
        if (earthquake.detectedTime) earthquakeDescription += `<div><b>Detected:</b> ${earthquake.detectedTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}<br/>(${timeAgo.format(earthquake.detectedTime)})</div>`;
        if (earthquake.feature === 'shakemap') {
            const shakemapData = earthquake as EarthquakeShakemapData;
            earthquakeDescription += `<div><b>MMI:</b> ${shakemapData.value}</div>`;
            earthquakeDescription += `<div><b>Shaking:</b> ${capitalize(shakemapData.shaking)}</div>`;
            earthquakeDescription += `<div><b>Description:</b> ${shakemapData.description}</div>`;
        }
        if (!earthquake.hasShakemap) {
            earthquakeDescription += `<br/><div>No shakemap data available.</div>`;
        }
        eventDescription = earthquakeDescription;
    } else if (eventType === 'lightning') {
        const lightning: LightningData = event;
        eventDescription += `<div><b>Detected:</b> ${lightning.detectedTime.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}<br/>(${timeAgo.format(lightning.detectedTime)})</div>`;
    } else if (eventType === 'powerOutage') {
        const powerOutage: PowerOutageData = event;
        let powerOutageDescription = ``;
        if (powerOutage.county && powerOutage.stateAbbr) powerOutageDescription += `<div><b>Location:</b> ${powerOutage.county} County, ${powerOutage.stateAbbr}</div>`;
        if (powerOutage.metersOut) {
            if (powerOutage.metersServed && powerOutage.percentOut) {
                powerOutageDescription += `<div><b>Customer Outages:</b> ${powerOutage.metersOut.toLocaleString()}/${powerOutage.metersServed.toLocaleString()} (${(powerOutage.percentOut * 100).toFixed(2)}%)</div>`;
            } else {
                powerOutageDescription += `<div><b>Customer Outages:</b> ${powerOutage.metersOut.toLocaleString()}</div>`;
            }
        }
        eventDescription = powerOutageDescription;
    } else if (eventType === 'storm') {
        const storm: StormData = event;
        let stormDescription = ``;
        if (storm.briefing) stormDescription += `<div>${storm.briefing}</div>`;
        // if (storm.stormType) stormDescription += `<div><b>Type:</b> ${storm.stormType}</div>`;
        // if (storm.startTime) stormDescription += `<div><b>Start Time:</b> ${storm.startTime.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'})}</div>`;
        // if (storm.endTime) stormDescription += `<div><b>End Time:</b> ${storm.endTime.toLocaleDateString('en-us', { weekday:"short", year:"numeric", month:"short", day:"numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'})}</div>`;
        // if (storm.avgPeakRoadIndex) stormDescription += `<div><b>Avg Peak Road Index:</b> ${storm.avgPeakRoadIndex.toFixed(1)}</div>`;
        // if (storm.avgPeakFloodIndex) stormDescription += `<div><b>Avg Peak Flood Index:</b> ${storm.avgPeakFloodIndex.toFixed(1)}</div>`;
        // if (storm.avgPeakDisruptionIndex) stormDescription += `<div><b>Avg Peak Disruption Index:</b> ${storm.avgPeakDisruptionIndex.toFixed(1)}</div>`;
        // if (storm.avgPeakWindGust) stormDescription += `<div><b>Avg Peak Wind Gusts:</b> ${storm.avgPeakWindGust.toFixed(1)} mph</div>`;
        // if (storm.maxRainAccumulation) stormDescription += `<div><b>Max Rainfall Accumulation:</b> ${storm.maxRainAccumulation.toFixed(1)} in</div>`;
        // if (storm.maxFrozenRainAccumulation) stormDescription += `<div><b>Max Frozen Rainfall Accumulation:</b> ${storm.maxFrozenRainAccumulation.toFixed(1)} in</div>`;
        // if (storm.maxSnowAccumulation) stormDescription += `<div><b>Max Snowfall Accumulation:</b> ${storm.maxSnowAccumulation.toFixed(1)} in</div>`;
        // if (storm.maxGust) stormDescription += `<div><b>Max Wind Gust:</b> ${storm.maxGust.toFixed(1)} mph</div>`;
        // if (storm.weatherFlags) stormDescription += `<div><b>Weather Flags:</b> ${storm.weatherFlags.join(', ').replace('_', ' ')}</div>`;
        // if (storm.affectedCountries) stormDescription += `<div><b>Affected Countres:</b> ${storm.affectedCountries}</div>`;
        eventDescription = `<div>${stormDescription}</div>`;
    } else if (eventType === 'stormReport') {
        const stormReport: StormReportData = event;
        let stormReportDescription = ``;
        if (stormReport.description) stormReportDescription += `<div><b>Description:</b> ${stormReport.description}</div>`;
        if (stormReport.location) stormReportDescription += `<div><b>Location:</b> ${stormReport.location}</div>`;
        if (stormReport.updatedTime) stormReportDescription += `<div><b>Last Updated:</b> ${stormReport.updatedTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}<br/>(${timeAgo.format(stormReport.updatedTime)})</div>`;
        eventDescription = stormReportDescription;
    } else if (eventType === 'volcano') {
        const volcano: VolcanoData = event;
        let volcanoDescription = ``;
        if (volcano.description) volcanoDescription += `<div><b>Description:</b> ${volcano.description}</div>`;
        if (volcano.updatedTime) volcanoDescription += `<div><b>Last Updated:</b> ${volcano.updatedTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}<br/>(${timeAgo.format(volcano.updatedTime)})</div>`;
        if (volcano.activityType) volcanoDescription += `<div><b>Activity Type:</b> ${volcano.activityType}</div>`;
        if (volcano.latestActivity) volcanoDescription += `<div><b>Latest Activity:</b> ${volcano.latestActivity}</div>`;
        eventDescription = volcanoDescription;
    } else if (eventType === 'fire') {
        const f: FireData = event;
        let fireDescription = ``;
        if (f.dateReported) fireDescription += `<div><b>Date Reported:</b> ${f.dateReported.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (f.lastUpdated) fireDescription += `<div><b>Last Updated:</b> ${f.lastUpdated.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}<br/>(${timeAgo.format(f.lastUpdated)})</div>`;
        eventDescription = fireDescription;
    } else if (eventType === 'wildfire') {
        const wf: WildfireData = event;
        let wildfireDescription = ``;
        if (wf.sizeAcres) wildfireDescription += `<div><b>Size:</b> ${wf.sizeAcres.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} acres</div>`;
        if (wf.percentContained) wildfireDescription += `<div><b>Contained:</b> ${(wf.percentContained * 100).toFixed(1)}%</div>`;
        if (wf.percentSuppressed) wildfireDescription += `<div><b>Suppressed:</b> ${(wf.percentSuppressed * 100).toFixed(1)}%</div>`;
        if (wf.complexity) wildfireDescription += `<div><b>Severity (1-5):</b> ${wf.complexity.split(' ')[1]}</div>`;
        if (wf.behavior) wildfireDescription += `<div><b>Spread Behavior:</b> ${wf.behavior}</div>`;
        if (wf.dateReported) wildfireDescription += `<div><b>Date Reported:</b> ${wf.dateReported.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" })}</div>`;
        if (wf.lastUpdated) wildfireDescription += `<div><b>Last Updated:</b> ${wf.lastUpdated.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric" })}</div>`;
        eventDescription = wildfireDescription;
    } else if (eventType === 'roadStatus') {
        const roadStatus: RoadStatusData = event;
        let roadStatusDescription = ``;
        if (roadStatus.roadway) roadStatusDescription += `<div><b>Route:</b> ${roadStatus.roadway}</div>`;
        if (roadStatus.description) roadStatusDescription += `<div><b>Description:</b> ${roadStatus.description}</div>`;
        if (roadStatus.status) roadStatusDescription += `<div><b>Surface Status:</b> ${roadStatus.status.replaceAll('_', ' ')}</div>`;
        if (roadStatus.start) roadStatusDescription += `<div><b>Start:</b> ${roadStatus.start}</div>`;
        if (roadStatus.end) roadStatusDescription += `<div><b>End:</b> ${roadStatus.end}</div>`;
        eventDescription = roadStatusDescription;
    } else if (eventType === 'roadWork') {
        const roadWork: RoadWorkData = event;
        let roadWorkDescription = ``;
        if (roadWork.roadway) roadWorkDescription += `<div><b>Route:</b> ${roadWork.roadway}</div>`;
        if (roadWork.title) roadWorkDescription += `<div><b>Title:</b> ${roadWork.title}</div>`;
        if (roadWork.description) roadWorkDescription += `<div><b>Description:</b> ${roadWork.description}</div>`;
        if (roadWork.startTime) roadWorkDescription += `<div><b>Start:</b> ${roadWork.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (roadWork.endTime) roadWorkDescription += `<div><b>End:</b> ${roadWork.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        eventDescription = roadWorkDescription;
    } else if (eventType === 'roadClosure') {
        const roadClosure: RoadClosureData = event;
        let roadClosureDescription = ``;
        if (roadClosure.roadway) roadClosureDescription += `<div><b>Route:</b> ${roadClosure.roadway}</div>`;
        if (roadClosure.reason) roadClosureDescription += `<div><b>Reason:</b> ${roadClosure.reason}</div>`;
        if (roadClosure.description) roadClosureDescription += `<div><b>Description:</b> ${roadClosure.description}</div>`;
        if (roadClosure.startTime) roadClosureDescription += `<div><b>Start:</b> ${roadClosure.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (roadClosure.endTime) roadClosureDescription += `<div><b>End:</b> ${roadClosure.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        eventDescription = roadClosureDescription;
    } else if (eventType === 'specialEvent') {
        const specialEvent: SpecialEventData = event;
        let specialEventDescription = ``;
        if (specialEvent.title) specialEventDescription += `<div><b>Title:</b> ${specialEvent.title}</div>`;
        if (specialEvent.specialEventType) specialEventDescription += `<div><b>Type:</b> ${specialEvent.specialEventType}</div>`;
        if (specialEvent.description) specialEventDescription += `<div><b>Description:</b> ${specialEvent.description}</div>`;
        if (specialEvent.startTime) specialEventDescription += `<div><b>Start:</b> ${specialEvent.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (specialEvent.endTime) specialEventDescription += `<div><b>End:</b> ${specialEvent.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        eventDescription = specialEventDescription;
    } else if (eventType === 'trafficCongestion') {
        const trafficCongestion: TrafficCongestionData = event;
        let trafficCongestionDescription = ``;
        if (trafficCongestion.roadway) trafficCongestionDescription += `<div><b>Route:</b> ${trafficCongestion.roadway}</div>`;
        if (trafficCongestion.trafficCongestionType) trafficCongestionDescription += `<div><b>Type:</b> ${trafficCongestion.trafficCongestionType}</div>`;
        if (trafficCongestion.reason) trafficCongestionDescription += `<div><b>Reason:</b> ${trafficCongestion.reason}</div>`;
        if (trafficCongestion.description) trafficCongestionDescription += `<div><b>Description:</b> ${trafficCongestion.description}</div>`;
        if (trafficCongestion.startTime) trafficCongestionDescription += `<div><b>Start:</b> ${trafficCongestion.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (trafficCongestion.endTime) trafficCongestionDescription += `<div><b>End:</b> ${trafficCongestion.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        eventDescription = trafficCongestionDescription;
    } else if (eventType === 'trafficIncident') {
        const trafficIncident: TrafficIncidentData = event;
        let trafficIncidentDescription = ``;
        if (trafficIncident.roadway) trafficIncidentDescription += `<div><b>Route:</b> ${trafficIncident.roadway}</div>`;
        if (trafficIncident.trafficIncidentType) trafficIncidentDescription += `<div><b>Type:</b> ${trafficIncident.trafficIncidentType}</div>`;
        if (trafficIncident.description) trafficIncidentDescription += `<div><b>Description:</b> ${trafficIncident.description}</div>`;
        if (trafficIncident.startTime) trafficIncidentDescription += `<div><b>Start:</b> ${trafficIncident.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (trafficIncident.endTime) trafficIncidentDescription += `<div><b>End:</b> ${trafficIncident.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (trafficIncident.source) trafficIncidentDescription += `<div><b>Source:</b> ${trafficIncident.source}</div>`;
        eventDescription = trafficIncidentDescription;
    } else if (eventType === 'truckWarning') {
        const truckWarning: TruckWarningData = event;
        let truckWarningDescription = ``;
        if (truckWarning.title) truckWarningDescription += `<div><b>Title:</b> ${truckWarning.title}</div>`;
        if (truckWarning.description) truckWarningDescription += `<div><b>Description:</b> ${truckWarning.description}</div>`;
        if (truckWarning.startTime) truckWarningDescription += `<div><b>Start:</b> ${truckWarning.startTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        if (truckWarning.endTime) truckWarningDescription += `<div><b>End:</b> ${truckWarning.endTime.toLocaleDateString('en-us', { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' })}</div>`;
        eventDescription = truckWarningDescription;
    } else if (eventType === 'weatherStation') {
        const weatherStation: WeatherStationData = event;
        let weatherStationDescription = ``;
        if (weatherStation.roadway) weatherStationDescription += `<div><b>Location:</b> ${weatherStation.roadway}</div>`;
        if (weatherStation.description) weatherStationDescription += `<div><b>Description:</b> ${weatherStation.description}</div>`;
        if (weatherStation.status) weatherStationDescription += `<div><b>Surface Status:</b> ${weatherStation.status.replaceAll('_', ' ')}</div>`;
        if (weatherStation.temperature) weatherStationDescription += `<div><b>Temperature:</b> ${weatherStation.temperature.toFixed(1)} F</div>`;
        if (weatherStation.precipitation) weatherStationDescription += `<div><b>Precipitation:</b> ${weatherStation.precipitation}</div>`;
        if (weatherStation.windSpeed) {
            if (weatherStation.windSpeedDirection) {
                weatherStationDescription += `<div><b>Wind Speed:</b> ${weatherStation.windSpeed.toFixed(1)} mph ${weatherStation.windSpeedDirection}</div>`;
            } else {
                weatherStationDescription += `<div><b>Wind Speed:</b> ${weatherStation.windSpeed.toFixed(1)} mph</div>`;
            }
        }
        if (weatherStation.windGust) {
            if (weatherStation.windGustDirection) {
                weatherStationDescription += `<div><b>Wind Gust:</b> ${weatherStation.windGust.toFixed(1)} mph ${event.windGustDirection}</div>`;
            } else {
                weatherStationDescription += `<div><b>Wind Gust:</b> ${weatherStation.windGust.toFixed(1)} mph</div>`;
            }
        }
        eventDescription = weatherStationDescription;
    }

    return eventDescription;

};

export interface HourRatingsData {
    time: Date;
    value: number;
}

export interface WeatherState {
    weatherData?: WeatherData;
    cyclones?: CycloneData[];
    earthquakes?: EarthquakeData[];
    lightning?: LightningData[];
    powerOutages?: PowerOutageData[];
    storms?: StormData[];
    stormReports?: StormReportData[];
    volcanoes?: VolcanoData[];
    fires?: FireData[];
    wildfires?: WildfireData[];
    weatherStations?: WeatherStationData[];
    hasEventsPermission: boolean;

    isFetching: boolean;
    eventsExpiryTime: number;
    cacheExpiryTime: number;
}

export interface MapViewState {
    center?: BasicLatLng;
    zoomLevel: number;
}

export interface HistoricalAccuracyViewState {
    isCalloutViewVisible: boolean;
}

export interface NowcastViewState {
    selectedMapCategorySlug: string;
    selectedMapType: ImpactMapType;

    paused: boolean;
    tileOpacity?: number,

    selectedGraphView: string;
}

export interface ImpactViewState {
    selectedMapCategorySlug: string;
    selectedMapType: ImpactMapType;
    selectedCyclone?: CycloneData;
    selectedEarthquake?: EarthquakeData;
    selectedLightning?: LightningData;
    selectedPowerOutage?: PowerOutageData;
    selectedStorm?: StormData;
    selectedStormReport?: StormReportData;
    selectedVolcano?: VolcanoData;
    selectedFire?: FireData;
    selectedWildfire?: WildfireData;

    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
    paused: boolean;

    tileOpacity?: number,
    currentTileset?: ImpactTileset;

    isCyclonesVisible: boolean;
    isEarthquakesVisible: boolean;
    isLightningVisible: boolean;
    isPowerOutagesVisible: boolean;
    isStormsVisible: boolean;
    isStormReportsVisible: boolean;
    isVolcanoesVisible: boolean;
    isFiresVisible: boolean;
    isWildfiresVisible: boolean;
    mapOptionsCollapsed: boolean;
    selectedGraphView: string;
}

export interface RadarViewState {
    weatherTilesets?: WeatherTilesetsByIndex;
    selectedSpeedMultiplierIndex: number;
    animationDelay: number;
    paused: boolean;
}

export interface AlertsViewState {
    possibleGovernmentalAlertNames?: string[];
}

export interface UserState {
    id: number;
    pictureURL?: string;
    token?: string;
    portalToken?: string;
    clientName?: string;

    isLoggingIn: boolean;
    lastLoginError?: string;

    submittingPasswordReset: boolean;
    passwordResetSuccess: boolean;
    passwordResetError?: string;

    cities: LocationData[];
    citiesMetadata: LoadableResultMetadata;
    savedRoutes: RouteData[];
    savedRoutesMetadata: LoadableResultMetadata;
    vehicles: VehicleTrackingData[];
    vehiclesMetadata: LoadableResultMetadata;
    driverNotifications: DriverNotification[]
    refreshingRouteIds: number[];
    refreshRouteError?: string;
    editRouteResponse?: RouteData | Error[];
    editLocationResponse?: LocationData | Error[];
    editDriverNotificationResponse?: DriverNotification | Error[];

    showImpactTab: boolean;
    showRoutesTab: boolean;
    showDisruptionIndex: boolean;
    showWildfireIndices: boolean;
    show511InPortal: boolean;
    showEventsInPortal: boolean;
    useLegacyTabDisplay: boolean;

    myAccount?: MyAccountState;
    lastAccountError?: string;

    currentPage: string;
}

// Prod Test Client (2491) and March V1 Prod Test (2533)
export const ClientId = {
    Production: {
        Prod_Test_Client: 2491,
        March_V1_Prod_Test: 2533,
        AFB: 2684,
        Falvey: 2669,
        Meijer: 2584,
        St_Tammany_Parish: 2700,
        Cvs_Vero_Beach: 2710,
        Werner: 2544,
        PGT: 2534,
        UPS: 2717,
        US_Xpress: 2730,
        Santa_Fe_Indian_School: 2736,
    },
    Staging: {
        Staging_Test_Client: 2517,
        US_Xpress: 2630,
    },
};

const globalFeatureWhitelist: { [environment: string]: number[] | undefined } = {
    'production': [ClientId.Production.Prod_Test_Client, ClientId.Production.March_V1_Prod_Test],
};
// default behavior is to allow features for all environments, all users
// when a environment is passed in the whitelist, then only users matching that whitelist
// can use the feature in that environment
export function isValidUserForFeature(currentUserId: number | undefined, whitelist: { [environment: string]: number[] | undefined }) {
    const environment = process.env.REACT_APP_ENV;
    if (!environment) {
        return false;
    }
    const whitelistForEnvironment = whitelist[environment];
    const globalWhitelistForEnvironment = globalFeatureWhitelist[environment];
    // no whitelist configured for this environment so we can move on
    if (whitelistForEnvironment === undefined && globalWhitelistForEnvironment === undefined) {
        return true;
    }
    const validUserIds = (whitelistForEnvironment ?? []).concat(globalWhitelistForEnvironment ?? []);
    // if we find the current user id in the list, then its a valid feature for them
    if (validUserIds.indexOf(currentUserId ?? -1) !== -1) {
        return true;
    }
    return false;
}

export interface MyAccountState {
    apiAccess?: ApiAccess;
    maxRoutes: number;
    maxLocations: number;
    isCurrentlySubscribed: boolean;
    isOnATrial: boolean;
    subscriptionEndsAt: Date;
    subscriptionEndBehavior: string;
    subscriptionEndReason: string;
    isStripeCustomer: boolean;
}

export enum PermissionName {
    ACCOUNT = 'account',
    ADMIN = 'admin',
    PORTAL = 'portal',
    IMPACT = 'ratings',
    STANDARD_WEATHER = 'weather',
    FORECAST_168_HOURS = 'extended_hours',
    FORECAST_384_HOURS = 'long_range',
    DISRUPTION_INDEX = 'disruption',
    IMPACT_TILES = 'tiling',
    WEATHER_TILES = 'weather_tiles',
    WILDFIRE_TILES = 'wildfire_tiles',
    HISTORICAL_IMPACT = 'ratings_historical',
    HISTORICAL_STANDARD_WEATHER = 'weather_historical',
    EVENTS = 'events',
    WEATHER_ALERTS = 'weather_alerts',

    RIGHTROUTE_FORECAST_STANDARD = 'eta',
    RIGHTROUTE_FORECAST_CUSTOM = 'rightroute_custom',
    RIGHTROUTE_FORECAST_RANGE = 'rightroute_range',
    RIGHTROUTE_HISTORICAL_STANDARD = 'rightroute_historical_standard',
    RIGHTROUTE_HISTORICAL_CUSTOM = 'rightroute_historical_custom',
}

export const isTilingPermission = (permission: PermissionName) => {
    switch (permission) {
        case PermissionName.IMPACT_TILES:
        case PermissionName.WEATHER_TILES:
            return true;
        default:
            return false;
    }
};

export const ALL_PERMISSIONS: string[] = [
    PermissionName.ACCOUNT,
    PermissionName.ADMIN,
    PermissionName.PORTAL,
    PermissionName.IMPACT,
    PermissionName.HISTORICAL_IMPACT,
    PermissionName.STANDARD_WEATHER,
    PermissionName.HISTORICAL_STANDARD_WEATHER,
    PermissionName.FORECAST_168_HOURS,
    PermissionName.FORECAST_384_HOURS,
    PermissionName.DISRUPTION_INDEX,
    PermissionName.IMPACT_TILES,
    PermissionName.WEATHER_TILES,
    PermissionName.WILDFIRE_TILES,
    PermissionName.RIGHTROUTE_FORECAST_STANDARD,
    PermissionName.RIGHTROUTE_FORECAST_CUSTOM,
    PermissionName.RIGHTROUTE_FORECAST_RANGE,
    PermissionName.RIGHTROUTE_HISTORICAL_STANDARD,
    PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM,
    PermissionName.EVENTS,
    PermissionName.WEATHER_ALERTS,
];

export interface ApiAccess {
    token: string;

    [PermissionName.IMPACT]: GrantInfo | undefined;
    [PermissionName.HISTORICAL_IMPACT]: GrantInfo | undefined;
    [PermissionName.STANDARD_WEATHER]: GrantInfo | undefined;
    [PermissionName.HISTORICAL_STANDARD_WEATHER]: GrantInfo | undefined;
    [PermissionName.RIGHTROUTE_FORECAST_STANDARD]: GrantInfo | undefined;
    [PermissionName.RIGHTROUTE_FORECAST_CUSTOM]: GrantInfo | undefined;
    [PermissionName.RIGHTROUTE_FORECAST_RANGE]: GrantInfo | undefined;
    [PermissionName.RIGHTROUTE_HISTORICAL_STANDARD]: GrantInfo | undefined;
    [PermissionName.RIGHTROUTE_HISTORICAL_CUSTOM]: GrantInfo | undefined;

    totalCost: number;
    cycleStartDate: Date;
    cycleEndDate: Date;
}

export interface GrantInfo {
    maxRequestsPerSecond: number;
    maxRequestsPerMinute: number;
    calls: number;
    price?: number;
}

export interface SelectedCityState {
    selectedCity?: LocationData;

    cityBeingSaved?: LocationData;
    citySavingError?: string;

    isGeocoding: boolean;

    abortController?: AbortController;
}

export interface Blurb {
    blurb: string;
    color: string;
    value: number;
}

export interface BlurbsByIndex {
    road?: Blurb[];
    power?: Blurb[];
    flood?: Blurb[];
    disruption?: Blurb[];
    life_property?: Blurb[];
    wildfire_spread?: Blurb[];
    wildfire_conditions?: Blurb[];
    temperature?: Blurb[];
    rain_accumulation?: Blurb[];
    snow_accumulation?: Blurb[];
    wind_speed?: Blurb[];
    wind_gust?: Blurb[];
}

export interface RatingsState {
    // loaded ratings data
    ratingsData: LoadableResult<RatingsDataWithLocationInfo>;
    subhourlyRatingsData: LoadableResult<RatingsDataWithLocationInfo>;
    wildfireData: LoadableResult<RatingsDataWithLocationInfo>

    blurbs: BlurbsByIndex;

    // latest run tilesets
    impactTilesets: LoadableResult<ImpactTilesetsByIndex>;
    weatherTilesets: LoadableResult<WeatherTilesetsByIndex>;
    subhourlyImpactTilesets: LoadableResult<ImpactTilesetsByIndex>;
    subhourlyWeatherTilesets: LoadableResult<WeatherTilesetsByIndex>;

    governmentalAlerts: LoadableResult<AlertData[]>;

    // abort controllers for pending requests
    blurbsAbortController?: AbortController;
    staticMapInfoAbortController?: AbortController;
}

export interface StoreState {
    // these correspond to reducers
    weather: WeatherState;
    user: UserState;
    selectedCity: SelectedCityState;
    ratings: RatingsState;

    mapView: MapViewState; // shared props for radar+alerts+impact map views
    impactView: ImpactViewState;
    radarView: RadarViewState;
    alertsView: AlertsViewState;
    routesView: RoutesViewState;
    placesView: PlacesViewState;
    dashboardView: DashboardViewState;
    trafficView: TrafficViewState;
    nowcastView: NowcastViewState;
}

export interface ImpactRunTimes {
    road: Date;
    power: Date;
    disruption: Date;
    flood: Date;
    life_property: Date;
    wildfire_spread?: Date;
    wildfire_conditions?: Date;
}

export interface WeatherRunTimes {
    radar: Date;
    temperature: Date;
    rain_accumulation: Date;
    snow_accumulation: Date;
    wind_speed: Date;
    wind_gust: Date;
}

export interface ImpactTileset {
    id: string;
    variable: string;

    time: Date;
    url: string;
}

export interface ImpactTilesetsByIndex {
    disruption: ImpactTileset[];
    flood: ImpactTileset[];
    power: ImpactTileset[];
    road: ImpactTileset[];
    life_property: ImpactTileset[];
    wildfire_spread?: ImpactTileset[];
    wildfire_conditions?: ImpactTileset[];

    createdAt?: ImpactRunTimes;
    runTimes?: ImpactRunTimes;
}

export interface WeatherTilesetsByIndex {
    radar: ImpactTileset[];
    temperature: ImpactTileset[];
    rain_accumulation: ImpactTileset[];
    snow_accumulation: ImpactTileset[];
    wind_speed: ImpactTileset[];
    wind_gust: ImpactTileset[];

    createdAt?: WeatherRunTimes;
    runTimes?: WeatherRunTimes;
}

/**
 * checks if two tilesets are for the same base forecast time
 * @param a 
 * @param b 
 * @returns true if the two tilesets have the same forecast time for all indices else false
 */
export function areTilesetsForSameTimes(a: ImpactTilesetsByIndex | WeatherTilesetsByIndex, b: ImpactTilesetsByIndex | WeatherTilesetsByIndex): boolean {
    if (a === undefined) {
        return false;
    }
    // should be kept in sync with the keys in ImpactTilesetsByIndex
    const tilesetKeys = ('road' in a) ? ['road', 'flood', 'power', 'disruption', 'life_property', 'wildfire_spread', 'wildfire_conditions']
        : ['temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust'];
    return tilesetKeys.every(key => {
        if (a[key]?.length > 0 && b[key]?.length > 0) {
            // both tilesets have the key so checck their time
            return a[key][0]['time'] === b[key][0]['time'];
        } else if ((key in a) === false && (key in b) === false) {
            // both tilesets don't have the key so ignore it
            return true;
        } else {
            // one tile set has the key and the other doesn't (don't think it should ever get her but return false just in case)
            return false;
        }
    });
}

export interface WeatherTileset {
    time: string;
    url: string;
}

export function parseFloatOptional(s: string): number | undefined {
    let ret = parseFloat(s);
    if (isNaN(ret) || ret === null) {
        return undefined;
    }
    return ret;
}

export interface FloodChanceData {
    name: string;
    date: Date;
    floodProbability: number;
    geoJson: any;
}


export function trueCondition(): boolean {
    return new Date().getTime() > 0;
}
import { CycloneData, EarthquakeData, FireData, LightningData, LocationData, PowerOutageData, RoadClosureData, RoadStatusData, RoadWorkData, SpecialEventData, StoreState, StormData, StormReportData, TrafficCongestionData, TrafficIncidentData, TruckWarningData, VehicleTrackingData, VolcanoData, WeatherStationData, WildfireData } from '../../types';
import { connect } from 'react-redux';
import { ClientNowcastPage } from '../../components/Client/Nowcast';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { SelectCity, geocodeCity, loadCity } from "../../actions/SelectedCity";
import { ClearRouteRefreshError, fetchUserCities, OpenedPage } from 'src/actions/User';
import { loadEventsIfNeeded, loadSubhourlyWeatherIfNeeded, loadWeatherIfNeeded } from 'src/actions/Weather';
import { loadGovernmentalAlertsIfNeeded, loadRatingsIfNeeded, loadSubhourlyRatingsIfNeeded } from 'src/actions/Ratings';
import { ImpactMapSection } from 'src/components/Client/Impact/sections';
import { MapType } from 'src/types/MapType';
import { NowcastSetSelectedMap, NowcastSetTileOpacity } from 'src/actions/NowcastView';
import { ClearSelectedEvent, ImpactSetAnimationSpeed, ImpactSetCyclonesVisible, ImpactSetEarthquakesVisible, ImpactSetFiresVisible, ImpactSetLightningVisible, ImpactSetPowerOutagesVisible, ImpactSetStormReportsVisible, ImpactSetStormsVisible, ImpactSetVolcanoesVisible, ImpactSetWildfiresVisible, SelectCyclone, SelectEarthquake, SelectFire, SelectLightning, SelectPowerOutage, SelectStorm, SelectStormReport, SelectVolcano, SelectWildfire } from 'src/actions/ImpactView';
import { isImpactKey, isWeatherKey } from 'src/types/RatingKey';
import { loadRoadConditionsIfNeeded, TrafficClearSelectedRoadCondition, TrafficSetSelectedRoadClosure, TrafficSetSelectedRoadStatus, TrafficSetSelectedRoadWork, TrafficSetSelectedSpecialEvent, TrafficSetSelectedTrafficCongestion, TrafficSetSelectedTrafficIncident, TrafficSetSelectedTruckWarning, TrafficSetSelectedWeatherStation, TrafficSetTrafficLayer } from 'src/actions/TrafficView';
import { DashboardOverlay } from 'src/components/Client/Dashboard';
import { refreshRoute, sendMessageToVehicle, SetSelectedRoute, SetSelectedVehicle } from '../../actions/DashboardView';
import { RouteData } from 'src/types/routes';


export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.nowcastView,
        userData: state.user,
        selectedCityState: state.selectedCity,
        selectedCity: state.selectedCity.selectedCity,
        selectedVehicle: state.dashboardView.selectedVehicle,
        selectedRoute: state.dashboardView.selectedRoute,
        subhourlyRatingsData: state.ratings.subhourlyRatingsData.value,
        ratingsData: state.ratings.ratingsData.value,
        weatherData: state.weather.weatherData,
        blurbs: state.ratings.blurbs,

        cyclones: state.weather.cyclones,
        earthquakes: state.weather.earthquakes,
        lightning: state.weather.lightning,
        powerOutages: state.weather.powerOutages,
        storms: state.weather.storms,
        stormReports: state.weather.stormReports,
        volcanoes: state.weather.volcanoes,
        fires: state.weather.fires,
        wildfires: state.weather.wildfires,
        hasEventsPermission: state.weather.hasEventsPermission,

        roadStatus: state.trafficView.roadStatus,
        roadWork: state.trafficView.roadWork,
        roadClosures: state.trafficView.roadClosures,
        specialEvents: state.trafficView.specialEvents,
        trafficCongestion: state.trafficView.trafficCongestion,
        trafficIncidents: state.trafficView.trafficIncidents,
        truckWarnings: state.trafficView.truckWarnings,
        weatherStations: state.trafficView.weatherStations,

        impactTilesets: state.ratings.impactTilesets?.value,
        subhourlyImpactTilesets: state.ratings.subhourlyImpactTilesets?.value,
        weatherTilesets: state.ratings.weatherTilesets?.value,
        subhourlyWeatherTilesets: state.ratings.subhourlyWeatherTilesets?.value,
        selectedSpeedMultiplierIndex: state.impactView.selectedSpeedMultiplierIndex,
        speedIncrements: [1, 2, 3, 0.5],
        baseAnimationDelay: 1000,
        tileOpacity: state.nowcastView.tileOpacity,

        governmentalAlerts: state.ratings.governmentalAlerts,

        isCyclonesVisible: state.impactView.isCyclonesVisible,
        isEarthquakesVisible: state.impactView.isEarthquakesVisible,
        isLightningVisible: state.impactView.isLightningVisible,
        isPowerOutagesVisible: state.impactView.isPowerOutagesVisible,
        isStormsVisible: state.impactView.isStormsVisible,
        isStormReportsVisible: state.impactView.isStormReportsVisible,
        isVolcanoesVisible: state.impactView.isVolcanoesVisible,
        isFiresVisible: state.impactView.isFiresVisible,
        isWildfiresVisible: state.impactView.isWildfiresVisible,

        isRoadStatusVisible: state.trafficView.isRoadStatusVisible,
        isRoadWorkVisible: state.trafficView.isRoadWorkVisible,
        isRoadClosuresVisible: state.trafficView.isRoadClosuresVisible,
        isSpecialEventsVisible: state.trafficView.isSpecialEventsVisible,
        isTrafficCongestionVisible: state.trafficView.isTrafficCongestionVisible,
        isTrafficIncidentsVisible: state.trafficView.isTrafficIncidentsVisible,
        isTruckWarningsVisible: state.trafficView.isTruckWarningsVisible,
        isWeatherStationsVisible: state.trafficView.isWeatherStationsVisible,

        selectedCyclone: state.impactView.selectedCyclone,
        selectedEarthquake: state.impactView.selectedEarthquake,
        selectedLightning: state.impactView.selectedLightning,
        selectedPowerOutage: state.impactView.selectedPowerOutage,
        selectedStorm: state.impactView.selectedStorm,
        selectedStormReport: state.impactView.selectedStormReport,
        selectedVolcano: state.impactView.selectedVolcano,
        selectedFire: state.impactView.selectedFire,
        selectedWildfire: state.impactView.selectedWildfire,

        selectedRoadStatus: state.trafficView.selectedRoadStatus,
        selectedRoadWork: state.trafficView.selectedRoadWork,
        selectedRoadClosure: state.trafficView.selectedRoadClosure,
        selectedSpecialEvent: state.trafficView.selectedSpecialEvent,
        selectedTrafficCongestion: state.trafficView.selectedTrafficCongestion,
        selectedTrafficIncident: state.trafficView.selectedTrafficIncident,
        selectedTruckWarning: state.trafficView.selectedTruckWarning,
        selectedWeatherStation: state.trafficView.selectedWeatherStation,

        showDisruptionIndex: state.user.showDisruptionIndex,
        showWildfireIndices: state.user.showWildfireIndices
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCitySelected: (city: LocationData) => {
            dispatch(SelectCity({ city }));
            if (city) {
                if (city.needsGeocoding) {
                    dispatch(geocodeCity(city));
                } else {
                    if (city.subhourlyImpactSummary?.source === 'HYPERR') {
                        dispatch(loadSubhourlyWeatherIfNeeded());
                        dispatch(loadSubhourlyRatingsIfNeeded());
                    }
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            }
        },
        onVehicleSelected: (vehicle: VehicleTrackingData | undefined) => {
            dispatch(SetSelectedVehicle({ selectedVehicle: vehicle }));
        },
        onVehicleMessageRequested: (vehicle: VehicleTrackingData, message: string, urgent: boolean) => dispatch(sendMessageToVehicle(vehicle, message, urgent)),
        setSelectedRoute: (route: RouteData | undefined) => dispatch(SetSelectedRoute({ selectedRoute: route })),
        onRouteRefreshRequested: (route: RouteData) => dispatch(refreshRoute(route)),
        onRouteRefreshErrorDismissed: () => dispatch(ClearRouteRefreshError()),
        onMapSelected: (selectedMapCategory: ImpactMapSection, selectedMapType: MapType) => {
            dispatch(NowcastSetSelectedMap({ selectedMapCategory, selectedMapType }));
            if (isWeatherKey(selectedMapType.ratingKey)) {
                dispatch(loadSubhourlyWeatherIfNeeded());
                dispatch(loadWeatherIfNeeded());
            } else if (isImpactKey(selectedMapType.ratingKey)) {
                dispatch(loadSubhourlyRatingsIfNeeded());
                dispatch(loadRatingsIfNeeded());
            }
        },
        onTrafficLayerSelected: (layer: DashboardOverlay | undefined) => {
            dispatch(TrafficSetTrafficLayer({ layer }));
            dispatch(TrafficClearSelectedRoadCondition());
        },
        onSetTileOpacity: (tileOpacity: number) => {
            dispatch(NowcastSetTileOpacity({ tileOpacity }));
        },
        onLoadEvents: () => {
            dispatch(loadEventsIfNeeded());
        },
        onLoadRoadStatus: () => {
            dispatch(loadRoadConditionsIfNeeded());
        },
        onReloadSelectedCity: (selectedCity: LocationData, token: string) => {
            dispatch(fetchUserCities(token, selectedCity?.id ? [selectedCity.id] : undefined));
            dispatch(loadSubhourlyRatingsIfNeeded());
            dispatch(loadRatingsIfNeeded());
        },
        onReloadSelectedCityData: () => {
            dispatch(loadSubhourlyWeatherIfNeeded());
            dispatch(loadWeatherIfNeeded());
            dispatch(loadSubhourlyRatingsIfNeeded());
            dispatch(loadRatingsIfNeeded());
        },
        onCyclonesToggled: (visible: boolean) => {
            dispatch(ImpactSetCyclonesVisible({ visible }));
        },
        onEarthquakesToggled: (visible: boolean) => {
            dispatch(ImpactSetEarthquakesVisible({ visible }));
        },
        onLightningToggled: (visible: boolean) => {
            dispatch(ImpactSetLightningVisible({ visible }));
        },
        onPowerOutagesToggled: (visible: boolean) => {
            dispatch(ImpactSetPowerOutagesVisible({ visible }));
        },
        onStormsToggled: (visible: boolean) => {
            dispatch(ImpactSetStormsVisible({ visible }));
        },
        onStormReportsToggled: (visible: boolean) => {
            dispatch(ImpactSetStormReportsVisible({ visible }));
        },
        onVolcanoesToggled: (visible: boolean) => {
            dispatch(ImpactSetVolcanoesVisible({ visible }));
        },
        onFiresToggled: (visible: boolean) => {
            dispatch(ImpactSetFiresVisible({ visible }));
        },
        onWildfiresToggled: (visible: boolean) => {
            dispatch(ImpactSetWildfiresVisible({ visible }));
        },
        onCycloneSelected: (cyclone: CycloneData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectCyclone({ cyclone }));
        },
        onEarthquakeSelected: (earthquake: EarthquakeData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectEarthquake({ earthquake }));
        },
        onLightningSelected: (lightning: LightningData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectLightning({ lightning }));
        },
        onPowerOutageSelected: (powerOutage: PowerOutageData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectPowerOutage({ powerOutage }));
        },
        onStormSelected: (storm: StormData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectStorm({ storm }));
        },
        onStormReportSelected: (stormReport: StormReportData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectStormReport({ stormReport }));
        },
        onVolcanoSelected: (volcano: VolcanoData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectVolcano({ volcano }));
        },
        onFireSelected: (fire: FireData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectFire({ fire }));
        },
        onWildfireSelected: (wildfire: WildfireData) => {
            dispatch(TrafficClearSelectedRoadCondition());
            dispatch(SelectWildfire({ wildfire }));
        },
        onRoadStatusSelected: (roadStatus?: RoadStatusData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadStatus({ roadStatus }));
        },
        onRoadWorkSelected: (roadWork?: RoadWorkData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadWork({ roadWork }));
        },
        onRoadClosureSelected: (roadClosure?: RoadClosureData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedRoadClosure({ roadClosure }));
        },
        onSpecialEventSelected: (specialEvent?: SpecialEventData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedSpecialEvent({ specialEvent }));
        },
        onTrafficCongestionSelected: (trafficCongestion?: TrafficCongestionData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTrafficCongestion({ trafficCongestion }));
        },
        onTrafficIncidentSelected: (trafficIncident?: TrafficIncidentData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTrafficIncident({ trafficIncident }));
        },
        onTruckWarningSelected: (truckWarning?: TruckWarningData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedTruckWarning({ truckWarning }));
        },
        onWeatherStationSelected: (weatherStation?: WeatherStationData) => {
            dispatch(ClearSelectedEvent());
            dispatch(TrafficSetSelectedWeatherStation({ weatherStation }));
        },
        onClearSelectedEvent: () => {
            dispatch(ClearSelectedEvent());
        },
        onClearSelectedRoadCondition: () => {
            dispatch(TrafficClearSelectedRoadCondition());
        },
        onAnimationSpeedChanged: (selectedSpeedMultiplierIndex: number, animationDelay: number) => {
            dispatch(ImpactSetAnimationSpeed({ selectedSpeedMultiplierIndex, animationDelay }));
        },
        openedPage: (page: string) => {
            dispatch(OpenedPage({ page }));
        },
        loadLocation: (locationId: string) => dispatch(loadCity(locationId)),
        loadGovernmentalAlertsIfNeeded: () => dispatch(loadGovernmentalAlertsIfNeeded()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientNowcastPage));

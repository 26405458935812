import {
    AlertData,
    LocationData,
    WeatherConditions,
    WeatherState,
    CycloneData,
    LightningData,
    EarthquakeData,
    StormData,
    VolcanoData,
    FireData,
    WildfireData,
    CyclonePointData,
    CycloneTrackData,
    CycloneSwathData,
    CycloneConeData,
    PowerOutageData,
    StormReportData,
    PowerUtilityData,
    EarthquakeShakemapData,
    EarthquakeEpicenterData
} from '../types';
import { LoadWeatherFail, LoadWeatherStart, LoadForecastStart, ReceiveWeatherData, WeatherAction, ReceiveEventsData, LoadEventsFail, LoadEventsStart, ReceiveEventsDataFailDueToMissingPermission } from '../actions/Weather';
import { EVENTS_CACHE_INTERVAL, WEATHER_CACHE_INTERVAL } from '../constants';
import { unmarshalAlertData } from 'src/types/unmarshal';
import moment from 'moment';
import { Feature, FeatureCollection } from 'geojson';
import stringifyDeterministic from 'json-stringify-deterministic';

// https://stackoverflow.com/a/52171480
export const cyrb53 = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export function weather(state: WeatherState, action: typeof WeatherAction.actions): WeatherState {
    switch (action.type) {
        case ReceiveWeatherData.type:
            let json = action.weatherData;

            let hourly: WeatherConditions[] = (json['hourly'] as [object]).map(hourlyJSON => {
                let hour: WeatherConditions = {
                    time: new Date((hourlyJSON['time'] as number) * 1000),
                    temperature: hourlyJSON['temperature'] as number,
                    rainfall: hourlyJSON['rainfall_rate'] as number,
                    snowfall: hourlyJSON['snowfall_rate'] as number,
                    rainAccumulation: hourlyJSON['rain_accumulation_since_forecast_start'] as number,
                    snowAccumulation: hourlyJSON['snow_accumulation_since_forecast_start'] as number,
                    totalPrecipitation: hourlyJSON['total_precipitation'] as number,
                    windSpeed: hourlyJSON['wind_speed'] as number,
                    windGust: hourlyJSON['wind_gust'] as number,
                    heatIndex: hourlyJSON['heat_index'] as number,
                    visibility: hourlyJSON['visibility'] as number,
                    windGustProbability: hourlyJSON['gust_gte_45_probability'] as number,
                };

                return hour;
            });

            let subhourly: WeatherConditions[] | undefined = undefined;

            if (json['subhourly'] !== undefined && json['subhourly'] !== null) {
                subhourly = (json['subhourly'] as [object]).map((subhourlyJSON, i) => {
                    let hour: WeatherConditions = {
                        time: new Date((subhourlyJSON['time'] as number) * 1000),
                        temperature: subhourlyJSON['temperature'] as number,
                        totalPrecipitation: subhourlyJSON['total_precipitation'] as number,
                        rainfall: subhourlyJSON['rainfall_rate'] as number,
                        snowfall: subhourlyJSON['snowfall_rate'] as number,
                        rainAccumulation: subhourlyJSON['rain_accumulation_since_forecast_start'] as number,
                        snowAccumulation: subhourlyJSON['snow_accumulation_since_forecast_start'] as number,
                        windSpeed: subhourlyJSON['wind_speed'] as number,
                        windGust: subhourlyJSON['wind_gust'] as number,
                        heatIndex: subhourlyJSON['heat_index'] as number,
                        visibility: subhourlyJSON['visibility'] as number,
                        lightningProbability: subhourlyJSON['lightning_probability'] as number,
                        windGustProbability: subhourlyJSON['gust_gte_45_probability'] as number,
                    };

                    return hour;
                });
            }

            // fill in the windgust data from hourly for the sites timeline to use
            subhourly = subhourly?.map((wdata: WeatherConditions) => {
                const shHour = moment(wdata.time).toDate();
                shHour.setMinutes(0);
                const hourConditions = hourly.find((hour: WeatherConditions) => hour.time.getTime() === shHour.getTime());
                wdata.windGustProbability = hourConditions?.windGustProbability;
                return wdata;
            });

            let city: LocationData = {
                zip: json['city']['zip'] as string,
                id: json['city']['id'] && json['city']['id'] as number,
                name: json['city']['name'] as string,
                latitude: json['city']['latitude'] as number,
                longitude: json['city']['longitude'] as number,
                needsGeocoding: false,
                impactSummaries: []
            };

            // reverse order of alerts to have higher priorty alerts at top of list
            let alerts: AlertData[] = (json['alerts'] as object[]).reverse().map(unmarshalAlertData);

            return {
                ...state,
                isFetching: false,
                weatherData: { hourly, subhourly, city, alerts },
                cacheExpiryTime: action.receivedAt + WEATHER_CACHE_INTERVAL,
            };
        case ReceiveEventsData.type:
            let eventsJson: Feature[] = (action.eventsData as FeatureCollection)['features'];
            let cyclones: CycloneData[] = [];
            let earthquakes: EarthquakeData[] = [];
            let lightning: LightningData[] = [];
            let powerOutages: PowerOutageData[] = [];
            let storms: StormData[] = [];
            let stormReports: StormReportData[] = [];
            let volcanoes: VolcanoData[] = [];
            let fires: FireData[] = [];
            let wildfires: WildfireData[] = [];

            // precompute some sets
            const cycloneHasModelForecastTracks = new Set();
            const earthquakeHasShakemap = new Set();
            eventsJson.forEach(eventJSON => {
                const properties = eventJSON['properties']!;
                const eventType = properties['wo_event_type'];
                if (eventType === 'cyclone' && (properties['feature'] === 'storm_track_point_alternate' || properties['feature'] === 'storm_track_alternate')) {
                    cycloneHasModelForecastTracks.add(properties['cyclone_id']);
                } else if (eventType === 'earthquake' && properties['feature'] === 'shakemap') {
                    earthquakeHasShakemap.add(eventJSON['id']);
                }
            });

            eventsJson.forEach(eventJSON => {
                const properties = eventJSON['properties']!;
                const eventType = properties['wo_event_type'];
                if (eventType === 'cyclone') {
                    if (properties['feature'] === 'storm_track_point' || properties['feature'] === 'storm_track_point_alternate' || properties['feature'] === 'disturbance_point') {
                        cyclones.push({
                            id: String(cyrb53(stringifyDeterministic(eventJSON))),
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            cycloneId: properties['cyclone_id'] as string,
                            name: properties['cyclone_name'] as string,
                            feature: properties['feature'],
                            hasModelForecastTracks: cycloneHasModelForecastTracks.has(eventJSON['id']),

                            category: properties['category'] ?? undefined as string | undefined,
                            gustMPH: properties['gust_mph'] ?? undefined as number | undefined,
                            windMPH: properties['wind_mph'] ?? undefined as number | undefined,
                            expectedArrivalTime: new Date(properties['expected_arrival_time_iso'] as string),
                            modelName: properties['model_name'] as string,
                            description: properties['description'] as string | undefined,
                            twoDayFormationCategory: properties['two_day_formation_category'] as string | undefined,
                        } as CyclonePointData);
                    } else if (properties['feature'] === 'storm_track' || properties['feature'] === 'storm_track_alternate' || properties['feature'] === 'disturbance_track') {
                        cyclones.push({
                            id: String(cyrb53(stringifyDeterministic(eventJSON))),
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            cycloneId: properties['cyclone_id'] as string,
                            name: properties['cyclone_name'] as string,
                            feature: properties['feature'],
                            hasModelForecastTracks: cycloneHasModelForecastTracks.has(eventJSON['id']),

                            modelName: properties['model_name'] as string,
                            description: properties['description'] as string | undefined,
                        } as CycloneTrackData);
                    } else if (properties['feature'] === 'storm_danger_swath') {
                        cyclones.push({
                            id: String(cyrb53(stringifyDeterministic(eventJSON))),
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            cycloneId: properties['cyclone_id'] as string,
                            name: properties['cyclone_name'] as string,
                            feature: properties['feature'],
                            hasModelForecastTracks: cycloneHasModelForecastTracks.has(eventJSON['id']),

                            description: (properties['description'] ?? undefined) as string | undefined,
                        } as CycloneSwathData);
                    } else if (properties['feature'] === 'storm_cone_of_uncertainty' || properties['feature'] === 'disturbance_area') {
                        cyclones.push({
                            id: String(cyrb53(stringifyDeterministic(eventJSON))),
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            cycloneId: properties['cyclone_id'] as string,
                            name: properties['cyclone_name'] as string,
                            feature: properties['feature'],
                            hasModelForecastTracks: cycloneHasModelForecastTracks.has(eventJSON['id']),

                            description: (properties['description'] ?? undefined) as string | undefined,
                        } as CycloneConeData);
                    }
                } else if (eventType === 'earthquake') {
                    // fallback can be removed after a prod deploy
                    const feature = (properties['feature'] as string | undefined) || 'epicenter';
                    if (feature === 'epicenter') {
                        earthquakes.push({
                            id: `${eventJSON['id']}_${feature}`,
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            earthquakeId: eventJSON['id'] as string,
                            feature,
                            magnitude: properties['magnitude'] as number,
                            status: properties['status'] as string,
                            detectedTime: new Date(properties['detected_time_iso'] as string),
                            hasShakemap: earthquakeHasShakemap.has(properties['id']),
                        } as EarthquakeEpicenterData);
                    } else if (feature === 'shakemap') {
                        earthquakes.push({
                            id: `${eventJSON['id']}_${feature}_${properties['value']}`,
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            earthquakeId: eventJSON['id'] as string,
                            feature,
                            magnitude: properties['magnitude'] as number,
                            status: properties['status'] as string,
                            detectedTime: new Date(properties['detected_time_iso'] as string),

                            value: properties['value'] as number,
                            shaking: properties['shaking'] as string,
                            description: properties['description'] as string,
                            hasShakemap: earthquakeHasShakemap.has(properties['id']),
                        } as EarthquakeShakemapData);
                    }
                } else if (eventType === 'lightning') {
                    lightning.push({
                        id: eventJSON['id'] as string,
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        energy: properties['flash_energy'] as number,
                        qualityFlag: properties['flash_quality_flag'] as boolean,
                        area: properties['flash_area'] as number,
                        detectedTime: new Date(properties['detected_time_iso'] as string),
                    });
                } else if (eventType === 'power_outage') {
                    const utilities: PowerUtilityData[] = properties['utilities_tracked'].map((utility: any) => {
                        return {
                            name: utility['name'],
                            metersOut: utility['meters_out'],
                            metersServed: utility['meters_served'],
                        } as PowerUtilityData;
                    });
                    powerOutages.push({
                        id: eventJSON['id'] as string,
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        county: properties['county_name'] as string,
                        state: properties['state'] as string,
                        stateAbbr: properties['state_abbreviation'] as string,
                        percentOut: properties['percent_out'] as number,
                        metersOut: properties['meters_out'] as number,
                        metersServed: properties['meters_served'] as number,
                        utilitiesTracked: utilities,
                        fill: properties['fill'] as string,
                        fillOpacity: properties['fill-opacity'] as number,
                    });
                } else if (eventType === 'storm') {
                    storms.push({
                        id: properties['storm_id'] as string,
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        stormType: properties['storm_type'] as string,
                        maxSnowAccumulation: properties['max_SNOW_ACCUM'] as number,
                        maxFrozenRainAccumulation: properties['max_FRZR_ACCUM'] as number,
                        maxRainAccumulation: properties['max_RAIN_ACCUM'] as number,
                        maxGust: properties['max_GUST'] as number,
                        avgPeakRoadIndex: properties['average_peak_wo_road_index'] as number,
                        avgPeakFloodIndex: properties['average_peak_wo_flood_index'] as number,
                        avgPeakDisruptionIndex: properties['average_peak_wo_disruption_index'] as number,
                        avgPeakWindGust: properties['average_peak_wind_gust'] as number,
                        weatherFlags: properties['weather_flags'] as string[],
                        affectedCountries: properties['affected_countries'].map((country: any) => `${country['name']}`).join(", ") as string,
                        startTime: new Date(properties['storm_start_time_iso'] as string),
                        endTime: new Date(properties['storm_end_time_iso'] as string),
                        briefing: properties['wo_event_briefing'] as string,
                    });
                } else if (eventType === 'storm_report') {
                    stormReports.push({
                        id: String(cyrb53(stringifyDeterministic(eventJSON))),
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        reportType: properties['wo_report_type'] as string,
                        magnitude: properties['magnitude'] as number,
                        units: properties['units'] as string,
                        description: properties['description'] as string,
                        location: properties['location'] as string,
                        nwsOffice: properties['nws_office'] as string,
                        markerColor: properties['marker-color'] as string,
                        updatedTime: new Date(properties['updated_iso'] as string),
                    });
                } else if (eventType === 'volcano') {
                    // filter unknown or dormant activity volcanoes
                    if (properties['activity_type'] && properties['activity_type'] !== "normal activity or dormant") {
                        volcanoes.push({
                            id: properties['id'] as string,
                            type: eventType,
                            geoJson: eventJSON as unknown as GeoJSON.Feature,

                            name: properties['name'] as string,
                            description: properties['description'] as string,
                            activityType: properties['activity_type'] as string,
                            latestActivity: properties['latest_activity'] as string,
                            updatedTime: new Date(properties['updated_iso'] as string),
                        });
                    }
                } else if (eventType === 'fire') {
                    fires.push({
                        id: String(cyrb53(stringifyDeterministic(eventJSON))),
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        dateReported: new Date(properties['detected_time_iso'] as string),
                        confidence: properties['confidence'] as number,
                    });
                } else if (eventType === 'wildfire') {
                    let dateReported = new Date(properties['created_iso'] as string);
                    dateReported = new Date(dateReported.getTime() - dateReported.getTimezoneOffset() * -60000);
                    let lastUpdated = new Date(properties['updated_iso'] as string);
                    lastUpdated = new Date(lastUpdated.getTime() - lastUpdated.getTimezoneOffset() * -60000);
                    wildfires.push({
                        id: properties['name'] as string,
                        type: eventType,
                        geoJson: eventJSON as unknown as GeoJSON.Feature,

                        name: properties['name'] as string,
                        dateReported: dateReported,
                        lastUpdated: lastUpdated,
                        sizeAcres: properties['acres_affected'] as number,
                        complexity: undefined,
                        behavior: undefined,
                        percentContained: properties['percent_contained'] as number,
                        percentSuppressed: properties['percent_suppressed'] as number,
                    });
                } else {
                    // console.log(`Event type ${eventType} not processed in Receive Events Data`);
                }

            });

            return {
                ...state,
                isFetching: false,
                cyclones: cyclones,
                earthquakes: earthquakes,
                // we want to render the new lightning first which is at the end of the array
                // when we use the partial rendering strategy (load x markers, yield the thread, repeat)
                // but then newer lightning markers will be below old ones...
                // could fix with Z index?
                // TODO: consider re-enabling depending on if we use yieldTimeout in MapManagerV2
                // lightning: lightning.reverse(),
                lightning: lightning,
                powerOutages: powerOutages,
                storms: storms,
                stormReports: stormReports,
                volcanoes: volcanoes,
                fires: fires,
                wildfires: wildfires,
                eventsExpiryTime: Date.now() + EVENTS_CACHE_INTERVAL,
                hasEventsPermission: true,
            };
        case ReceiveEventsDataFailDueToMissingPermission.type:
            return { ...state, hasEventsPermission: false };
        case LoadWeatherFail.type:
        case LoadEventsFail.type:
            return { ...state, isFetching: false };
        case LoadWeatherStart.type:
            return { ...state, isFetching: true, weatherData: undefined };
        case LoadForecastStart.type:
        case LoadEventsStart.type:
            return { ...state, isFetching: true };
    }

    return state || {
        weatherData: undefined,
        // default to true so that loading spinners show while we figure out if it is going to fail
        hasEventsPermission: true,
        isFetching: false,
        cacheExpiryTime: -1,
    };
}

import { ALERTS_HOST, API_HOST, GRANULAR_RATINGS_HOST } from '../constants';
import {
    AlertData,
    Blurb,
    BlurbsByIndex, HourRatingsData, ImpactRunTimes,
    ImpactTilesetsByIndex,
    LoadableResult,
    LocationData,
    RatingsDataWithLocationInfo,
    RatingsState,
    StoreState,
    WeatherTilesetsByIndex,
    areTilesetsForSameTimes,
} from '../types';
import { Action, ActionCreator } from 'redux';
import { createLogoutAction } from './User';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { areCoordsEqual } from '../reducers/Ratings';
import { getPostData } from './SelectedCity';
import { RatingKey } from '../types/RatingKey';
import { action, props, union } from 'ts-action';
import { loadSubhourlyWeatherIfNeeded, loadWeatherIfNeeded } from './Weather';
import { unmarshalAlertData, unmarshalImpactTilesets, unmarshalWeatherTilesets } from '../types/unmarshal';
import { Config } from '../components/shared/useConfig';
import { store } from 'src';

export const ReceiveImpactTilesetsData = action('RECEIVE_IMPACT_TILESETS_DATA', props<{ impactTilesetsResult: LoadableResult<ImpactTilesetsByIndex> }>());

export const ReceiveSubhourlyImpactTilesetsData = action('RECEIVE_SUBHOURLY_IMPACT_TILESETS_DATA', props<{ impactTilesetsResult: LoadableResult<ImpactTilesetsByIndex> }>());

export const ReceiveWeatherTilesetsData = action('RECEIVE_WEATHER_TILESETS_DATA', props<{ weatherTilesetsResult: LoadableResult<WeatherTilesetsByIndex> }>());

export const ReceiveSubhourlyWeatherTilesetsData = action('RECEIVE_SUBHOURLY_WEATHER_TILESETS_DATA', props<{ weatherTilesetsResult: LoadableResult<WeatherTilesetsByIndex> }>());

export const ReceiveGovernmentalAlertData = action('RECEIVE_GOVERNMENTAL_ALERT_DATA', props<{ governmentalAlertsResult: LoadableResult<AlertData[]> }>());

export const ReceiveRatingsData = action('RECEIVE_RATINGS_DATA', props<{
    ratingsData: LoadableResult<RatingsDataWithLocationInfo>;
    subhourly: boolean;
}>());

export const ReceiveWildfireRatingsData = action('RECEIVE_WILDFIRE_RATINGS_DATA', props<{
    ratingsData: LoadableResult<RatingsDataWithLocationInfo>;
}>());

export const LoadBlurbsStart = action('LOAD_BLURBS_START', props<{ abortController: AbortController }>());

export const ReceiveBlurbsData = action('RECEIVE_BLURBS_DATA', props<{ blurbs: BlurbsByIndex }>());

export const RatingsAction =
    union(ReceiveRatingsData,
        ReceiveWildfireRatingsData,
        LoadBlurbsStart, ReceiveBlurbsData,
        ReceiveImpactTilesetsData, ReceiveWeatherTilesetsData,
        ReceiveSubhourlyImpactTilesetsData, ReceiveSubhourlyWeatherTilesetsData,
        ReceiveGovernmentalAlertData
    );

export function fetchRatingsData(
    token: string,
    city: LocationData,
    options: { subhourly: boolean, force: boolean } = { subhourly: false, force: false },
) {
    return (dispatch: ThunkDispatch<StoreState, void, Action<any>>, getState: () => StoreState) => {
        const state = getState();
        const subhourly = options.subhourly;
        const resultData = subhourly ? state.ratings.subhourlyRatingsData : state.ratings.ratingsData;
        const impactRunTimes = subhourly ? state.ratings.subhourlyImpactTilesets.value?.runTimes : state.ratings.impactTilesets.value?.runTimes;

        if (impactRunTimes === undefined) {
            throw new Error("loading ratings without knowing latest run times first");
        }

        if (!options.force && resultData.isCacheValid()) {
            return Promise.resolve(resultData);
        }

        resultData.abort();
        resultData.setLoading();

        dispatch(ReceiveRatingsData({ ratingsData: resultData.copy(), subhourly }));

        return fetchRatingsOnce(token, resultData.abortController, city.latitude, city.longitude, impactRunTimes, subhourly).then(data => {
            // THIS IS FOR TESTING ONLY
            // was useful for determining whether the wrong data was being shown in the graphs
            // subhourly should be 5s, hourly should be 10s
            // data.disruption = data.disruption.map(d => { return { ...d, value: subhourly ? 5 : 10 }; });
            // data.road = data.road.map(d => { return { ...d, value: subhourly ? 5 : 10 }; });
            // data.power = data.power.map(d => { return { ...d, value: subhourly ? 5 : 10 }; });
            // data.flood = data.disruption.map(d => { return { ...d, value: subhourly ? 5 : 10 }; });
            // data.life_property = data.life_property.map(d => { return { ...d, value: subhourly ? 5 : 10 }; });
            // END TESTING ONLY

            resultData.setSuccess(data);

            dispatch(ReceiveRatingsData({ ratingsData: resultData.copy(), subhourly, }));

            return resultData;
        }).catch((error => {
            console.log("error loading ratings", error);
            resultData.setError(error);
            dispatch(ReceiveRatingsData({ ratingsData: resultData.copy(), subhourly, }));
            return resultData;
        }));
    };
}

export function fetchRatingsOnce(token: string, abortController: AbortController, latitude: number, longitude: number, impactRunTimes: ImpactRunTimes | undefined, subhourly: boolean = false): Promise<RatingsDataWithLocationInfo> {
    let url = `${GRANULAR_RATINGS_HOST}/forecast/hourly?token=${token}`;

    let runTime: any = undefined;
    if (impactRunTimes) {
        runTime = {};
        for (const ratingKey of ['road', 'disruption', 'power', 'flood', 'life_property']) {
            runTime[ratingKey] = impactRunTimes[ratingKey];
        }
    }
    let postDataParams = {
        index: 'all',
        latitude: latitude,
        longitude: longitude,
    };

    // subhourly adjustments
    if (subhourly) {
        url = `${GRANULAR_RATINGS_HOST}/forecast/subhourly?token=${token}`;

        postDataParams['return'] = 'full_forecast';
    } else {
        postDataParams['run_time'] = runTime;
        postDataParams['return'] = 'full_forecast,power_v2';
    }

    let postData: RequestInit = getPostData(
        postDataParams,
        abortController
    );

    return requestWrapper(() => fetch(url, postData)).then(
        (response: Response) => {
            let json = response.json();
            if (json) {
                return json;
            } else {
                console.log("no response json", response);
                throw new Error("no response json");
            }
        },
        (error: Error) => {
            console.log('error making ratings request.', error);
            throw error;
        }
    ).then((json?: JSON) => {
        if (!json) {
            throw new Error("json was undefined");
        }
        if (json['error']) {
            if (json['error'] === 'location outside model bounds') {
                throw new Error("location outside model bounds");
            }
            throw new Error(`unknown error: ${json['error']}`);
        }

        return getRatingsFromJSON(json['locations'][0], ['road', 'disruption', 'flood', 'power', 'life_property']);
    });
}

export function fetchWildfireRatingsData(token: string, city: LocationData, options: { force: boolean } = { force: false }) {
    return (dispatch: ThunkDispatch<StoreState, void, Action<any>>, getState: () => StoreState) => {
        const state = getState();
        const resultData = state.ratings.wildfireData;

        if (!options.force && resultData.isCacheValid()) {
            return Promise.resolve();
        }

        resultData.abort();
        resultData.setLoading();

        dispatch(ReceiveWildfireRatingsData({ ratingsData: resultData.copy(), }));
        return fetchWildfireRatingsOnce(token, resultData.abortController, city.latitude, city.longitude).then(data => {
            resultData.setSuccess(data);
            dispatch(ReceiveWildfireRatingsData({ ratingsData: resultData, }));
        }).catch((error => {
            console.log("error loading wildfire ratings", error);
            resultData.setError(error);
            return dispatch(ReceiveWildfireRatingsData({ ratingsData: resultData, }));
        }));
    };
}

export function fetchWildfireRatingsOnce(token: string, abortController: AbortController, latitude: number, longitude: number): Promise<RatingsDataWithLocationInfo> {
    let url = `${GRANULAR_RATINGS_HOST}/wildfire?token=${token}`;
    let postData: RequestInit = getPostData(
        {
            latitude: latitude,
            longitude: longitude,
        },
        abortController
    );

    return requestWrapper(() => fetch(url, postData)).then(
        (response: Response) => {
            let json = response.json();
            if (json) {
                return json;
            } else {
                console.log("no response json", response);
                throw new Error("no response json");
            }
        },
        (error: Error) => {
            console.log('error making ratings request.', error);
            throw error;
        }
    ).then((json?: JSON) => {
        if (!json) {
            throw new Error("json was undefined");
        }

        if (json['error'] === 'location outside model bounds') {
            throw new Error("location outside model bounds");
        } else {
            return getRatingsFromJSON(json['locations'][0], ['wildfire_spread', 'wildfire_conditions']);
        }
    });
}

function shouldFetchRatingsData(state: RatingsState, hasRatingsAccess: boolean, selectedCity: LocationData, subhourly: boolean = false): boolean {
    if (!hasRatingsAccess) {
        return false;
    }
    // TODO: is it fine to just use road as a proxy here?
    const desiredRatingKey = 'road';

    const isCacheValid = subhourly ? state.subhourlyRatingsData.isCacheValid() : state.ratingsData.isCacheValid();
    const stale = !isCacheValid;

    // if the first hour of data does not match the first hour of the tiles,
    // then we know we are using a different run time
    let differentRunTime = false;
    if (subhourly) {
        const firstRatingData: HourRatingsData | undefined = state.subhourlyRatingsData.value?.[desiredRatingKey]?.[0];
        const selectedCityRunTime: Date | undefined = selectedCity?.subhourlyImpactSummary?.sourceRunTime;
        if (firstRatingData && selectedCityRunTime && firstRatingData?.time.getTime() !== selectedCityRunTime.getTime()) {
            differentRunTime = true;
        }
    } else {
        const firstHourOfData: HourRatingsData | undefined = state.ratingsData.value?.[desiredRatingKey]?.[0];
        if (firstHourOfData?.time.getTime() !== state.impactTilesets.value?.[desiredRatingKey]?.[0].time.getTime()) {
            differentRunTime = true;
        }
    }

    let differentCity = false;
    let missing = false;
    let ratingsData = subhourly ? state.subhourlyRatingsData.value : state.ratingsData.value;
    if (ratingsData === undefined) {
        missing = true;
    } else if (ratingsData[desiredRatingKey] !== undefined && ratingsData[desiredRatingKey].length === 0) {
        missing = true;
    } else if (!areCoordsEqual(ratingsData.latitude, selectedCity.latitude) || !areCoordsEqual(ratingsData.longitude, selectedCity.longitude)) {
        differentCity = true;
    }

    console.debug(`shouldFetchRatingsData(missing=${missing}, stale=${stale}, differentRunTime=${differentRunTime}, differentCity=${differentCity})`);

    return missing || stale || differentRunTime || differentCity;
}

function getBlurbsFromJSON(json: JSON): BlurbsByIndex {
    const blurbs = json['blurbs'] as object;
    const colors = json['colors'] as object;
    const values = json['values'] as object;

    let ret: BlurbsByIndex = {};
    for (const index of ['road', 'flood', 'disruption', 'power', 'life_property', 'wildfire_spread', 'wildfire_conditions', 'temperature', 'rain_accumulation', 'snow_accumulation', 'wind_speed', 'wind_gust']) {
        const indexBlurbs = blurbs[index] as string[];
        const indexColors = colors[index] as string[];
        const indexValues = values[index] as number[];
        if (indexBlurbs?.length > 0) {
            ret[index] = indexBlurbs.map((blurb, index) => {
                let b: Blurb = {
                    blurb,
                    color: indexColors[index],
                    value: indexValues[index]
                };
                return b;
            });
        }
    }

    return ret;
}

export function relevantRatingKeys(showDisruptionIndex: boolean, showWildfireIndices: boolean): RatingKey[] {
    let ratingKeys: RatingKey[] = ['road', 'flood', 'power', 'life_property'];
    if (showDisruptionIndex) {
        ratingKeys.splice(1, 0, 'disruption');
    }
    if (showWildfireIndices) {
        ratingKeys = [...ratingKeys, ...['wildfire_spread', 'wildfire_conditions'] as RatingKey[]];
    }
    return ratingKeys;
}

// throws if input is invalid
function getRatingsFromJSON(json: JSON, ratings: RatingKey[]): RatingsDataWithLocationInfo {
    let ratingsData = {
        latitude: json['latitude'] as number,
        longitude: json['longitude'] as number,
        timezone: (json['time_zone'] ?? undefined) as string | undefined,

        road: [],
        power: [],
        disruption: [],
        flood: [],
        life_property: [],
        wildfire_spread: [],
        wildfire_conditions: []
    };

    for (const hour of json['indices']) {
        for (const key of ratings) {
            let value = hour[`wo_${key}_index`] as number;
            if (value !== undefined) {
                ratingsData[key].push({
                    time: new Date(hour['time'] as number * 1000),
                    value
                });
            }
        }
    }

    return ratingsData;
}

export const loadRatingsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        const state = getState();

        const portalToken = state.user.portalToken;
        const selectedCity = state.selectedCity.selectedCity;

        if (typeof portalToken === 'undefined' || typeof selectedCity === 'undefined' || selectedCity.needsGeocoding || state.ratings.impactTilesets.value?.runTimes === undefined) {
            return Promise.reject();
        }

        const force = shouldFetchRatingsData(state.ratings, true, selectedCity, true);
        dispatch(fetchRatingsData(portalToken, { ...selectedCity }, { subhourly: false, force, }));
        dispatch(fetchWildfireRatingsData(portalToken, { ...selectedCity }, { force }));
        return Promise.resolve();
    };
};

export const loadSubhourlyRatingsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        const state = getState();

        const portalToken = state.user.portalToken;
        const selectedCity = state.selectedCity.selectedCity;

        if (typeof portalToken === 'undefined' || typeof selectedCity === 'undefined') {
            return Promise.reject();
        }

        // TODO: how to do this dispatching vs awaiting?
        // TODO: just combine wildfire directly into ratings data to avoid this (to some extent)?
        const force = shouldFetchRatingsData(state.ratings, true, selectedCity, true);
        dispatch(fetchRatingsData(portalToken, { ...selectedCity }, { subhourly: true, force, }));
        dispatch(fetchWildfireRatingsData(portalToken, { ...selectedCity }));
        return Promise.resolve();
    };
};

export const loadImpactTilesetsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (force: boolean = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const portalToken = state.user.portalToken;

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        const impactTilesetsResult = state.ratings.impactTilesets;

        if (!force && impactTilesetsResult.isCacheValid()) {
            return Promise.resolve();
        }

        if (impactTilesetsResult.loading) {
            return Promise.resolve();
        }

        impactTilesetsResult.abort();
        impactTilesetsResult.setLoading();

        dispatch(ReceiveImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));

        try {
            const response = await requestWrapper(() => fetch(`${API_HOST}/tiling/impact?token=${portalToken}&return=full_forecast,creation_metadata,power_v2`, {
                signal: impactTilesetsResult.abortController.signal,
            }));
            const json = await response.json();

            const wildfireResponse = await requestWrapper(() => fetch(`${API_HOST}/tiling/wildfire?token=${portalToken}&return=full_forecast,creation_metadata`, {
                signal: impactTilesetsResult.abortController.signal,
            }));
            const wildfiresJson = await wildfireResponse.json();
            if (wildfiresJson.success) {
                json.wildfire_spread = wildfiresJson.wildfire_spread;
                json.wildfire_conditions = wildfiresJson.wildfire_conditions;
                json.impact_tilesets_created_at = { ...json.impact_tilesets_created_at, ...wildfiresJson.wildfire_tilesets_created_at };
            }

            const oldImpactTilesets = state.ratings.impactTilesets.value;

            const newTilesets = unmarshalImpactTilesets(json);

            impactTilesetsResult.setSuccess(newTilesets);

            dispatch(ReceiveImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));

            const shouldReload = oldImpactTilesets === undefined || !areTilesetsForSameTimes(oldImpactTilesets, newTilesets);
            if (shouldReload) {
                // TODO: this is a infinite loop of reloading if the ratings fails to load and the tilesets reload doesnt fix it
                // this happened because the staging DB was having issues ingesting data so, in theory, this isn't a common
                // situation, but it does make an outage worse because then our server starts getting spammed
                console.log('detected new tileset run times -- reloading weather and ratings');
                dispatch(loadWeatherIfNeeded());
                dispatch(loadRatingsIfNeeded());
            }

        } catch (error) {
            console.log("loading impact and/or wildfire tilesets error", error);

            impactTilesetsResult.setError(error);

            dispatch(ReceiveImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));
        }
    };
};

export const loadSubhourlyImpactTilesetsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (force: boolean = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const token = state.user.token;
        const portalToken = state.user.portalToken;

        if (token === undefined) {
            return Promise.resolve();
        }

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        const impactTilesetsResult = state.ratings.subhourlyImpactTilesets;

        if (!force && impactTilesetsResult.isCacheValid()) {
            return Promise.resolve();
        }

        if (impactTilesetsResult.loading) {
            return Promise.resolve();
        }

        impactTilesetsResult.abort();
        impactTilesetsResult.setLoading();

        dispatch(ReceiveSubhourlyImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));

        try {
            const response = await requestWrapper(() => fetch(`${API_HOST}/tiling/impact/subhourly?token=${portalToken}&return=full_forecast,creation_metadata`, {
                signal: impactTilesetsResult.abortController.signal,
            }));
            const json = await response.json();

            const oldImpactTilesets = state.ratings.subhourlyImpactTilesets.value;

            const newTilesets = unmarshalImpactTilesets(json);

            impactTilesetsResult.setSuccess(newTilesets);

            dispatch(ReceiveSubhourlyImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));

            const shouldReload = oldImpactTilesets === undefined || !areTilesetsForSameTimes(oldImpactTilesets, newTilesets);
            if (shouldReload) {
                // TODO: this is a infinite loop of reloading if the ratings fails to load and the tilesets reload doesnt fix it
                // this happened because the staging DB was having issues ingesting data so, in theory, this isn't a common
                // situation, but it does make an outage worse because then our server starts getting spammed
                console.log('detected new tileset run times -- reloading weather and ratings');
                dispatch(loadSubhourlyWeatherIfNeeded());
                dispatch(loadSubhourlyRatingsIfNeeded());
            }

        } catch (error) {
            console.log("loading subhourly impact tilesets error", error);

            impactTilesetsResult.setError(error);

            dispatch(ReceiveSubhourlyImpactTilesetsData({ impactTilesetsResult: impactTilesetsResult.copy() }));
        }
    };
};

export const loadWeatherTilesetsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (force: boolean = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const portalToken = state.user.portalToken;

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        const weatherTilesetsResult = state.ratings.weatherTilesets;

        if (!force && weatherTilesetsResult.isCacheValid()) {
            return Promise.resolve();
        }

        if (weatherTilesetsResult.loading) {
            return Promise.resolve();
        }

        weatherTilesetsResult.abort();
        weatherTilesetsResult.setLoading();

        dispatch(ReceiveWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));

        return requestWrapper(() => fetch(`${API_HOST}/tiling/weather?token=${portalToken}&return=full_forecast,creation_metadata`, {
            signal: weatherTilesetsResult.abortController.signal,
        }))
            .then(response => response.json())
            .then(json => {
                const oldWeatherTilesets = state.ratings.weatherTilesets.value;

                const newTilesets = unmarshalWeatherTilesets(json);

                weatherTilesetsResult.setSuccess(newTilesets);

                dispatch(ReceiveWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));

                const shouldReload = oldWeatherTilesets === undefined || !areTilesetsForSameTimes(oldWeatherTilesets, newTilesets);
                if (shouldReload) {
                    console.log('detected new weather tileset run times -- reloading weather and ratings');
                    dispatch(loadWeatherIfNeeded());
                    dispatch(loadRatingsIfNeeded());
                }
            })
            .catch(error => {
                console.log("loading weather tilesets error", error);

                weatherTilesetsResult.setError(error);

                dispatch(ReceiveWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));
            });
    };
};

export const loadSubhourlyWeatherTilesetsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (force: boolean = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const portalToken = state.user.portalToken;

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        const weatherTilesetsResult = state.ratings.subhourlyWeatherTilesets;

        if (!force && weatherTilesetsResult.isCacheValid()) {
            return Promise.resolve();
        }

        if (weatherTilesetsResult.loading) {
            return Promise.resolve();
        }

        weatherTilesetsResult.abort();
        weatherTilesetsResult.setLoading();

        dispatch(ReceiveSubhourlyWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));

        return requestWrapper(() => fetch(`${API_HOST}/tiling/weather/subhourly?token=${portalToken}&return=full_forecast,creation_metadata`, {
            signal: weatherTilesetsResult.abortController.signal,
        }))
            .then(response => response.json())
            .then(json => {
                const oldWeatherTilesets = state.ratings.subhourlyWeatherTilesets.value;

                const newTilesets = unmarshalWeatherTilesets(json);

                weatherTilesetsResult.setSuccess(newTilesets);

                dispatch(ReceiveSubhourlyWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));

                const shouldReload = oldWeatherTilesets === undefined || !areTilesetsForSameTimes(oldWeatherTilesets, newTilesets);
                if (shouldReload) {
                    console.log('detected new subhourly weather tileset run times -- reloading weather and ratings');
                    dispatch(loadSubhourlyWeatherIfNeeded());
                    dispatch(loadSubhourlyRatingsIfNeeded());
                }
            })
            .catch(error => {
                console.log("loading subhourly weather tilesets error", error);

                weatherTilesetsResult.setError(error);

                dispatch(ReceiveSubhourlyWeatherTilesetsData({ weatherTilesetsResult: weatherTilesetsResult.copy() }));
            });
    };
};

export const loadGovernmentalAlertsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (force: boolean = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const portalToken = state.user.portalToken;

        if (portalToken === undefined) {
            return Promise.resolve();
        }

        const governmentalAlertsResult = state.ratings.governmentalAlerts;

        if (!force && governmentalAlertsResult.isCacheValid()) {
            return Promise.resolve();
        }

        if (governmentalAlertsResult.loading) {
            return Promise.resolve();
        }

        governmentalAlertsResult.abort();
        governmentalAlertsResult.setLoading();

        dispatch(ReceiveGovernmentalAlertData({ governmentalAlertsResult: governmentalAlertsResult.copy() }));

        const showGlobalAlerts = Config.getBoolean(Config.Key.ShowGlobalAlerts);
        const countriesParam = showGlobalAlerts ? '&countries=all' : '';

        return requestWrapper(() => fetch(`${ALERTS_HOST}/weather/current?token=${portalToken}${countriesParam}`, {
            signal: governmentalAlertsResult.abortController.signal,
        }))
            .then(response => response.json())
            .then(governmentalAlertsGeojson => {
                const features = governmentalAlertsGeojson['features'].map(unmarshalAlertData);

                governmentalAlertsResult.setSuccess(features);

                dispatch(ReceiveGovernmentalAlertData({ governmentalAlertsResult: governmentalAlertsResult.copy() }));
            })
            .catch(error => {
                console.log("loading governmental alerts error", error);

                governmentalAlertsResult.setError(error);

                dispatch(ReceiveGovernmentalAlertData({ governmentalAlertsResult: governmentalAlertsResult.copy() }));
            });
    };
};

export const loadBlurbsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        const state = getState();
        let token = state.user.portalToken;
        if (token === undefined) {
            // not logged in yet, no need
            return Promise.resolve();
        }

        if (state.ratings.blurbs.disruption !== undefined &&
            state.ratings.blurbs.road !== undefined &&
            state.ratings.blurbs.power !== undefined &&
            state.ratings.blurbs.flood !== undefined &&
            state.ratings.blurbs.life_property !== undefined) {
            // blurbs already loaded
            return Promise.resolve();
        }

        if (state.ratings.blurbsAbortController) {
            state.ratings.blurbsAbortController.abort();
        }
        let abortController = new AbortController();
        dispatch(LoadBlurbsStart({ abortController }));

        let hasBlurbsAccess = getState().user.showImpactTab;
        if (!hasBlurbsAccess) {
            // no ratings access, won't see impact tab, no need
            return Promise.resolve();
        }

        return requestWrapper(() => fetch(`${GRANULAR_RATINGS_HOST}/blurbs?token=${token}&return=power_v2`, { signal: abortController.signal }))
            .then(response => response.json())
            .then(json => getBlurbsFromJSON(json))
            .then(blurbs => {
                dispatch(ReceiveBlurbsData({ blurbs }));
            })
            .catch(error => {
                console.log("blurbs error", error);
            });
    };
};

// wrape requests to create default response code actions for all requests
export function requestWrapper(runRequest: () => Promise<Response>, additionalWaitTime: number = 0, handle401Error: boolean = true): Promise<Response> {
    return runRequest().then(async (response: Response) => {
        if (handle401Error && response.status === 401) {
            store.dispatch(createLogoutAction(undefined));
            throw new Error("not authenticated");
        }
        if (response.status === 429) {
            const resetTimeRaw = response.headers.get('x_rate_limit_reset') ?? response.headers.get('x-rate-limit-reset');

            let resetTime: number;
            if (resetTimeRaw === null || resetTimeRaw === undefined || isNaN(parseFloat(resetTimeRaw))) {
                // if the delta is NaN, have a sanity fallback of 1 sec
                console.warn("could not get reset time from 429 response, sleeping to next second by default.");
                resetTime = new Date().getTime() / 1000 + 1;
            } else {
                resetTime = parseFloat(resetTimeRaw);
            }

            const delta = resetTime * 1000 - new Date().getTime();

            console.info('[rate limiting]', `server says to sleep until: ${new Date(resetTime * 1000)}`);
            console.info('[rate limiting]', `currently ${new Date()}`);
            console.info('[rate limiting]', `delta for sleep is ${delta} plus additional backoff time of ${additionalWaitTime}`);

            const totalWaitTime = additionalWaitTime + Math.max(delta, 0);
            let nextAdditionalWaitTime: number;
            if (additionalWaitTime === 0) {
                nextAdditionalWaitTime = 1000;
            } else if (additionalWaitTime > 10000) {
                throw new Error("ran out of retries when exponentially backing off -- hit max time limit");
            } else {
                nextAdditionalWaitTime = additionalWaitTime * 2;
            }

            return delay(totalWaitTime).then(() => requestWrapper(runRequest, nextAdditionalWaitTime));
        }

        return response;
    });
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

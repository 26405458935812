import {
    RouteData,
    RouteWaypoint
} from '../types/routes';
import { BasicLatLng, StoreState, UserState, VehicleType } from '../types';
import { Action, ActionCreator, Dispatch } from 'redux';
import axios, { AxiosResponse } from 'axios';
import querystring from 'query-string';
import { action, props, union } from 'ts-action';
import { delay, requestWrapper } from "./Ratings";
import { API_HOST, RIGHTROUTE_HOST } from "../constants";
import { ThunkAction } from "redux-thunk";
import { ReceiveUpdatedRoute, ReceiveUserRouteCreated, ReceiveUserRouteDeleted, ReceiveUserRouteUpdatedError, ReceiveUserRouteUpdatedSuccess } from "./User";
import { parseRouteError, parseRouteResults, unmarshalRoute } from 'src/types/unmarshal';
import { trackEvent } from 'src/analytics';

export const SetOriginOnRoutesTab = action('SET_ORIGIN_ON_ROUTES_TAB', props<{ origin: RouteWaypoint }>());
export const SetDestinationOnRoutesTab = action('SET_DESTINATION_ON_ROUTES_TAB', props<{ destination: RouteWaypoint }>());
export const SetWaypointsOnRoutesTab = action('SET_WAYPOINTS_ON_ROUTES_TAB', props<{ waypoints: RouteWaypoint[] }>());
export const SetDepartureTimeOnRoutesTab = action('SET_DEPARTURE_TIME_ON_ROUTES_TAB', props<{ departureTime: Date }>());
export const SetAboveTemperatureThresholdOnRoutesTab = action('SET_ABOVE_TEMPERATURE_THRESHOLD_ON_ROUTES_TAB', props<{ temperature: number }>());
export const SetBelowTemperatureThresholdOnRoutesTab = action('SET_BELOW_TEMPERATURE_THRESHOLD_ON_ROUTES_TAB', props<{ temperature: number }>());
export const SetVehicleTypeOnRoutesTab = action('SET_VEHICLE_TYPE_ON_ROUTES_TAB', props<{ vehicleType: VehicleType }>());
export const SetSelectedRoute = action('SET_SELECTED_ROUTE', props<{ route?: RouteData }>());
export const SetSelectedTabOnRoutesForm = action('SET_SELECTED_TAB_ON_ROUTES_FORM', props<{ selectedTab: number }>());
export const SetRunningOnRoutesTab = action('SET_RUNNING_ON_ROUTES_TAB', props<{ route: RouteData }>());
export const ReceiveErrorOnRoutesTab = action('RECEIVE_ERROR_ON_ROUTES_TAB', props<{ error: Error }>());
export const ClearErrorOnRoutesTab = action('CLEAR_ERROR_ON_ROUTES_TAB');
export const ReceiveResultsOnRoutesTab = action('RECEIVE_RESULTS_ROUTES_TAB', props<{ results: RouteData }>());
export const RoutesMapCenterChanged = action('ROUTES_MAP_CENTER_CHANGED', props<{ center: BasicLatLng }>());
export const RoutesMapZoomLevelChanged = action('ROUTES_MAP_ZOOM_LEVEL_CHANGED', props<{ zoomLevel: number }>());

export const StartSaveRoute = action('START_SAVE_ROUTE', props<{ route: RouteData }>());
export const ReceiveSaveRouteSuccess = action('RECEIVE_SAVE_ROUTE_SUCCESS', props<{ route: RouteData }>());
export const ReceiveSaveRouteError = action('RECEIVE_SAVE_ROUTE_ERROR', props<{ route: RouteData; error: string }>());

export const RoutesViewAction = union(
    RoutesMapCenterChanged, RoutesMapZoomLevelChanged,
    SetSelectedTabOnRoutesForm, SetSelectedRoute,
    SetOriginOnRoutesTab, SetDestinationOnRoutesTab, SetWaypointsOnRoutesTab, SetDepartureTimeOnRoutesTab,
    SetAboveTemperatureThresholdOnRoutesTab, SetBelowTemperatureThresholdOnRoutesTab, SetVehicleTypeOnRoutesTab,
    SetRunningOnRoutesTab, ReceiveErrorOnRoutesTab, ReceiveResultsOnRoutesTab,
    StartSaveRoute, ReceiveSaveRouteSuccess, ReceiveSaveRouteError,
    ClearErrorOnRoutesTab
);

export const getRightrouteRequestUrl = (user: UserState, origin: RouteWaypoint, destination: RouteWaypoint, waypoints: RouteWaypoint[] | undefined, departureTime: Date, aboveTemperatureThreshold: number | undefined, belowTemperatureThreshold: number | undefined, vehicleType: VehicleType | undefined) => {
    const token = user.portalToken;

    const queryParams = {
        token,
        origin: `${origin.latitude},${origin.longitude}`,
        destination: `${destination.latitude},${destination.longitude}`,
        departure_time: departureTime.toISOString(),
        vehicle_type: vehicleType ?? 'car',
        return: 'weather_summary,impact_summary,slowdown_polylines_v2,road_risk_polylines_v2'
    };

    if (waypoints) {
        // waypoint format:"lat,long;stop_duration=number_of_seconds_to_stop" separated with |
        const waypointsStr = waypoints.map(wp => {
            let waypointStr = `${wp.latitude},${wp.longitude}`;
            // routes forms stop duration  in hours, route APi tajes stop duration in seconds so multiply * 60 * 60
            if (wp.stopDuration !== undefined) waypointStr += `;stop_duration=${wp.stopDuration * 60 * 60}`;
            return waypointStr;
        }).join('|');
        queryParams['waypoints'] = waypointsStr;
    }

    if (aboveTemperatureThreshold !== undefined && !Number.isNaN(aboveTemperatureThreshold)) {
        queryParams['above_temperature_thresholds'] = `${aboveTemperatureThreshold}`;
    }
    if (belowTemperatureThreshold !== undefined && !Number.isNaN(belowTemperatureThreshold)) {
        queryParams['below_temperature_thresholds'] = `${belowTemperatureThreshold}`;
    }
    if ('above_temperature_thresholds' in queryParams || 'below_temperature_thresholds' in queryParams) {
        queryParams['return'] = queryParams['return'] + ',temperature_summary';
    }

    const query = querystring.stringify(queryParams);
    return `${RIGHTROUTE_HOST}/forecast?${query}`;
};

export function requestWrapper_axios(runRequest: () => Promise<AxiosResponse>, additionalWaitTime: number = 0): Promise<AxiosResponse> {
    return runRequest().catch(error => {
        if (!error.response) {
            throw error;
        }

        const response = error.response;
        if (response.status === 429) {
            const resetTimeRaw = response.headers['x_rate_limit_reset'] ?? response.headers['x-rate-limit-reset'];

            let resetTime: number;
            if (resetTimeRaw === null || resetTimeRaw === undefined || isNaN(parseFloat(resetTimeRaw))) {
                // if the delta is NaN, have a sanity fallback of 1 sec
                console.warn("could not get reset time from 429 response, sleeping to next second by default.");
                resetTime = new Date().getTime() / 1000 + 1;
            } else {
                resetTime = parseFloat(resetTimeRaw);
            }

            const delta = resetTime * 1000 - new Date().getTime();

            console.info('[rate limiting - axios]', `server says to sleep until: ${new Date(resetTime * 1000)}`);
            console.info('[rate limiting - axios]', `currently ${new Date()}`);
            console.info('[rate limiting - axios]', `delta for sleep is ${delta} plus additional backoff time of ${additionalWaitTime}`);

            const totalWaitTime = additionalWaitTime + Math.max(delta, 0);
            let nextAdditionalWaitTime: number;
            if (additionalWaitTime === 0) {
                nextAdditionalWaitTime = 1000;
            } else if (additionalWaitTime > 10000) {
                throw new Error("ran out of retries when exponentially backing off -- hit max time limit");
            } else {
                nextAdditionalWaitTime = additionalWaitTime * 2;
            }

            return delay(totalWaitTime).then(() => requestWrapper_axios(runRequest, nextAdditionalWaitTime));
        }

        return response;
    });
}

export const runRoute = (route: RouteData) => {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        dispatch(SetRunningOnRoutesTab({ route }));

        try {
            const user = getState().user;
            if (!user.portalToken) return;

            const url = getRightrouteRequestUrl(user, route.origin, route.destination, route.waypoints, route.departureTime, route.aboveTemperatureThreshold, route.belowTemperatureThreshold, route.vehicleData?.type);

            const rightRouteResponse = await requestWrapper_axios(() => axios.get(url)).catch(error => {
                if (!error.response) {
                    throw new CouldNotReachServerError(error);
                } else {
                    throw new Error('RightRoute response had an unhandled error');
                }
            });

            const body = rightRouteResponse.data;
            const error = parseRouteError(rightRouteResponse, body);
            if (error) {
                throw error;
            }
            const routeResults = parseRouteResults(body);

            dispatch(ReceiveResultsOnRoutesTab({ results: { ...route, latestRouteResults: routeResults } }));
        } catch (error) {
            console.log('error in axios', error);
            dispatch(ReceiveErrorOnRoutesTab({ error }));
        }
    };
};

export const saveRoute: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (route: RouteData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('saving route without token');
        }

        trackEvent('Route Added');

        const bodyParams = {
            token,
            origin_lat: route.origin.latitude,
            origin_long: route.origin.longitude,
            origin_label: route.origin.label,
            destination_lat: route.destination.latitude,
            destination_long: route.destination.longitude,
            destination_label: route.destination.label,
            departure_time: route.departureTime.toISOString(),
        };

        if (route.waypoints) {
            // waypoint format:"lat,long;stop_duration=number_of_seconds_to_stop" separated with |
            const waypointsStr = route.waypoints.map(wp => {
                let waypointStr = `${wp.latitude},${wp.longitude}`;
                // routes forms stop duration  in hours, route APi tajes stop duration in seconds so multiply * 60 * 60
                if (wp.stopDuration !== undefined) waypointStr += `;stop_duration=${wp.stopDuration * 60 * 60}`;
                return waypointStr;
            }).join('|');
            bodyParams['waypoints'] = waypointsStr;
        }

        if (route.aboveTemperatureThreshold !== undefined && !Number.isNaN(route.aboveTemperatureThreshold)) {
            bodyParams['above_temperature_thresholds'] = `${route.aboveTemperatureThreshold}`;
        }
        if (route.belowTemperatureThreshold !== undefined && !Number.isNaN(route.belowTemperatureThreshold)) {
            bodyParams['below_temperature_thresholds'] = `${route.belowTemperatureThreshold}`;
        }


        let postData: RequestInit = {
            body: JSON.stringify(bodyParams),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        dispatch(StartSaveRoute({ route }));

        return requestWrapper(() => fetch(`${API_HOST}/routes.json`, postData))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error saving route.', error);
                    dispatch(ReceiveSaveRouteError({ route, error: `${error}` }));
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error saving route, from server:', error);
                    dispatch(ReceiveSaveRouteError({ route, error }));
                    return Promise.reject();
                }

                dispatch(ReceiveSaveRouteSuccess({ route }));
                const createdRoute = unmarshalRoute(json['route']);
                dispatch(ReceiveUserRouteCreated({ route: createdRoute }));

                return Promise.resolve();
            });
    };
};

export const deleteRoute: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (route: RouteData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('deleting route without token');
        }

        const payload: RequestInit = {
            cache: 'no-cache',
            method: 'DELETE',
            mode: 'cors'
        };

        return requestWrapper(() => fetch(`${API_HOST}/routes/${route.id}.json?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error deleting route.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error deleting route, from server:', error);
                    return Promise.reject();
                }
                dispatch(ReceiveUserRouteDeleted({ route: route }));
                return Promise.resolve();
            });
    };
};

export const updateRoute: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (route: RouteData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('updating route without token');
        }

        const payload: RequestInit = {
            cache: 'no-cache',
            method: 'PATCH',
            mode: 'cors',
            body: JSON.stringify({
                token,
                route: {
                    external_id: route.externalId,
                    origin_lat: route.origin.latitude,
                    origin_long: route.origin.longitude,
                    origin_label: route.origin.label,
                    destination_lat: route.destination.latitude,
                    destination_long: route.destination.longitude,
                    destination_label: route.destination.label,
                    departure_time: route.departureTime.toISOString(),
                },
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/routes/${route.id}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error updating route.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error updating route, from server:', error);
                    dispatch(ReceiveUserRouteUpdatedError({ errors: [new Error(error)] }));
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error updating route, from server:', errors);
                    dispatch(ReceiveUserRouteUpdatedError({ errors: errors.map((e: string) => new Error(e)) }));
                    return Promise.reject();
                }

                const updatedRoute = unmarshalRoute(json['route']);
                dispatch(ReceiveUpdatedRoute({ route: updatedRoute }));
                dispatch(ReceiveUserRouteUpdatedSuccess({ route: updatedRoute }));
                return Promise.resolve();
            });
    };
};

export class DirectionsNotFoundError extends Error { }
export class InternalRouteRunError extends Error { }
export class InvalidDepartureTimeError extends Error { }
export class RouteEndOutOfRangeError extends Error { }
export class RouteOutOfModelBoundsError extends Error {
    location: string;

    constructor(location: string) {
        super();
        this.location = location;
    }
}
export class CouldNotReachServerError extends Error {
    underlyingError: Error;

    constructor(underlyingError: Error) {
        super();
        this.underlyingError = underlyingError;
    }
}
export class GenericBadRequestError extends Error {
    message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }
}

export class RouteSaveError extends Error {
    message: string;

    constructor(message: string) {
        super();
        this.message = message;
    }
}


import { BasicLatLng, StoreState, VehicleType } from '../../types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ClientRoutesPage } from '../../components/Client/Routes';
import { RouteData, RouteWaypoint } from '../../types/routes';
import {
    ClearErrorOnRoutesTab,
    RoutesMapCenterChanged,
    RoutesMapZoomLevelChanged,
    runRoute, saveRoute,
    SetAboveTemperatureThresholdOnRoutesTab,
    SetBelowTemperatureThresholdOnRoutesTab,
    SetDepartureTimeOnRoutesTab,
    SetDestinationOnRoutesTab,
    SetOriginOnRoutesTab, SetSelectedRoute, SetSelectedTabOnRoutesForm, SetVehicleTypeOnRoutesTab, SetWaypointsOnRoutesTab
} from '../../actions/RoutesView';
import { loadCity } from '../../actions/SelectedCity';

export function mapStateToProps(state: StoreState, _ownProps: RouteComponentProps<any>) {
    return {
        ...state.routesView,

        center: state.routesView.center,
        zoomLevel: state.routesView.zoomLevel,

        user: state.user,
        savedRoutes: state.user?.savedRoutes ?? [],
        selectedCity: state.selectedCity.selectedCity
    };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<StoreState, void, Action<any>>) {
    return {
        onCenterChanged: (center: BasicLatLng) => dispatch(RoutesMapCenterChanged({ center })),
        onZoomLevelChanged: (zoomLevel: number) => dispatch(RoutesMapZoomLevelChanged({ zoomLevel })),

        setSelectedTab: (selectedTab: number) => dispatch(SetSelectedTabOnRoutesForm({ selectedTab })),
        setSelectedRoute: (route: RouteData) => dispatch(SetSelectedRoute({ route })),
        setOrigin: (origin: RouteWaypoint) => dispatch(SetOriginOnRoutesTab({ origin })),
        setDestination: (destination: RouteWaypoint) => dispatch(SetDestinationOnRoutesTab({ destination })),
        setWaypoints: (waypoints: RouteWaypoint[]) => dispatch(SetWaypointsOnRoutesTab({ waypoints })),
        setDepartureTime: (departureTime: Date) => dispatch(SetDepartureTimeOnRoutesTab({ departureTime })),
        setAboveTemperatureThreshold: (temperature: number) => dispatch(SetAboveTemperatureThresholdOnRoutesTab({ temperature })),
        setBelowTemperatureThreshold: (temperature: number) => dispatch(SetBelowTemperatureThresholdOnRoutesTab({ temperature })),
        setVehicleType: (vehicleType: VehicleType) => dispatch(SetVehicleTypeOnRoutesTab({ vehicleType })),
        saveRoute: (route: RouteData) => dispatch(saveRoute(route)),
        runRoute: (route: RouteData) => dispatch(runRoute(route)),
        clearError: () => dispatch(ClearErrorOnRoutesTab()),

        loadLocation: (locationId: string) => dispatch(loadCity(locationId)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientRoutesPage));

import { RouteWaypoint, locationDataToRouteWaypoint, routeWaypointToLocationData } from '../../../types/routes';
import * as React from 'react';
import moment, { Moment } from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, capitalize, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid-pro';
import { cardBackgroundColor } from 'src/constants';
import { MaterialPlaceTextfield } from './MaterialPlaceTextfield';
import { AddCircleOutlineOutlined, HighlightOffOutlined, SwapVertOutlined } from '@mui/icons-material';
import { ClientId, isValidUserForFeature, VehicleType } from 'src/types';
import { Spacer } from '../../Public/PricingCalculatorEmbed';
import { DateTimePicker } from '@mui/x-date-pickers';

interface Props {
    origin?: RouteWaypoint;
    destination?: RouteWaypoint;
    waypoints?: RouteWaypoint[];
    aboveTemperatureThreshold?: number;
    belowTemperatureThreshold?: number;
    departureTime: Moment | null;
    token: string;
    userId: number;
    vehicleType?: VehicleType;
    setOrigin: (origin?: RouteWaypoint) => void;
    setDestination: (destination?: RouteWaypoint) => void;
    setWaypoints: (waypoints?: RouteWaypoint[]) => void;
    setDepartureTime: (departureTime: Moment | null) => void;
    setAboveTemperatureThreshold: (temperature?: number | undefined) => void;
    setBelowTemperatureThreshold: (temperature?: number | undefined) => void;
    setVehicleType: (vehicleType: VehicleType) => void;
}

export const RouteEntryForm = (props: Props) => {
    let fieldStyle: React.CSSProperties = { marginBottom: 10, width: '97%' };

    // need to create an array of refs to keep track of thewaypoit stop ducation inputs inivudually
    const stopDurationRefs = props.waypoints?.map(() => React.createRef<HTMLInputElement>());
    // the index of the stop duration taht was expanded to call focus for the correct ref
    const [stopDurationExpanded, setStopDurationExpanded] = React.useState<number | undefined>(undefined);
    // only show vehicle type selector for interal use
    const isPreviewDeploy = window.location.hostname === "localhost" || window.location.hostname.endsWith('-weatheroptics.vercel.app');

    React.useEffect(() => {
        if (stopDurationExpanded !== undefined && stopDurationRefs !== undefined) {
            const currentRef = stopDurationRefs[stopDurationExpanded];
            if (currentRef.current !== undefined) {
                currentRef.current!.focus();
            }
        }
    }, [stopDurationExpanded]);

    const originInput = (
        <div className={'location-textfield'}>
            <div style={fieldStyle}>
                <MaterialPlaceTextfield
                    userId={props.userId}
                    token={props.token}
                    label={'Origin Location'}
                    placeholder={'Choose origin, or click on the map...'}
                    value={routeWaypointToLocationData(props.origin)}
                    onChange={(waypoint) => props.setOrigin(locationDataToRouteWaypoint(waypoint))}
                    logTag='OriginAutocompleteLocationTextField'
                />
            </div>
            {props.waypoints && props.waypoints.length > 0 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Spacer stretch={true} />
                <Tooltip title={'Remove this destination'} placement="top">
                    <IconButton style={{ margin: 'auto' }} onClick={() => {
                        props.setOrigin(props.waypoints![0]);
                        props.setWaypoints(props.waypoints?.length === 1 ? undefined : props.waypoints!.splice(0, 1));
                    }}>
                        {<HighlightOffOutlined />}
                    </IconButton>
                </Tooltip>
            </div>}
        </div>
    );

    const destinationInput = (
        <div className={'location-textfield'}>
            <div style={fieldStyle}>
                <MaterialPlaceTextfield
                    userId={props.userId}
                    token={props.token}
                    label={'Destination Location'}
                    placeholder={`Choose destination${props.origin ? ', or click on the map' : ''}...`}
                    value={routeWaypointToLocationData(props.destination)}
                    onChange={(waypoint) => props.setDestination(locationDataToRouteWaypoint(waypoint))}
                    logTag='DestinationAutocompleteLocationTextField'
                />
            </div>
            {props.waypoints && props.waypoints.length > 0 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Spacer stretch={true} />
                <Tooltip title={'Remove this destination'} placement="top">
                    <IconButton style={{ margin: 'auto' }} onClick={() => {
                        let updatedWaypoints: RouteWaypoint[] = props.waypoints!.slice();
                        props.setDestination(updatedWaypoints.pop());
                        props.setWaypoints(updatedWaypoints.length === 0 ? undefined : updatedWaypoints);
                    }}>
                        {<HighlightOffOutlined />}
                    </IconButton>
                </Tooltip>
            </div>}
        </div>
    );

    let waypointInputs: JSX.Element[] | undefined = undefined;
    if (props.waypoints !== undefined) {
        waypointInputs = props.waypoints.map((wp: RouteWaypoint, i: number) => (
            <div>
                <div className={'location-textfield'}>
                    <div style={fieldStyle}>
                        <MaterialPlaceTextfield
                            userId={props.userId}
                            token={props.token}
                            label={`Stop ${i + 1}`}
                            placeholder={`Choose destination${props.origin ? ', or click on the map' : ''}...`}
                            value={routeWaypointToLocationData(wp)}
                            onChange={(waypoint) => {
                                let wps: RouteWaypoint[] = [...props.waypoints!];
                                wps[i] = locationDataToRouteWaypoint(waypoint)!;
                                props.setWaypoints(wps);
                            }}
                            logTag={`Waypoint${i + 1}AutocompleteLocationTextField`}
                        />
                    </div>
                    {props.waypoints && props.waypoints.length > 0 && <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Spacer stretch={true} />
                        <Tooltip title={'Remove this destination'} placement="top">
                            <IconButton style={{ margin: 'auto' }} onClick={() => {
                                let updatedWaypoints = undefined;
                                if (props.waypoints?.length !== 1) {
                                    updatedWaypoints = props.waypoints!.slice();
                                    updatedWaypoints.splice(i, 1);
                                }
                                props.setWaypoints(updatedWaypoints);
                            }}>
                                {<HighlightOffOutlined />}
                            </IconButton>
                        </Tooltip>
                    </div>}
                </div>
                {wp !== undefined && <div>
                    <Accordion style={{ backgroundColor: cardBackgroundColor, marginTop: '0px', marginBottom: '10px' }} onChange={(e, expanded) => setStopDurationExpanded(expanded ? i : undefined)}>
                        <AccordionSummary expandIcon={<GridExpandMoreIcon />} >
                            <Typography variant="body1" color='#c6c8c9'>+ Stop Duration</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={fieldStyle}>
                                <TextField
                                    label='Stop Duration'
                                    type="number"
                                    inputRef={stopDurationRefs && stopDurationRefs[i]}
                                    fullWidth
                                    value={wp !== undefined ? wp.stopDuration : ''}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        let wps: RouteWaypoint[] = [...props.waypoints!];
                                        wps[i].stopDuration = event.target.value === '' ? undefined : parseFloat(event.target.value);
                                        props.setWaypoints(wps);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                    }}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>}
            </div>
        ));
    }

    /**
    * restrict add additional destinations button in production to only Air Force (2684)
    */
    const addDestinationButtonVisible = isValidUserForFeature(props.userId, { 'production': [ClientId.Production.AFB, ClientId.Production.UPS, ClientId.Production.Santa_Fe_Indian_School] }) && props.origin && props.destination && (props.waypoints === undefined || props.waypoints?.length < 25);
    let addDestinationButton = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
            {addDestinationButtonVisible && <IconButton style={{ fontSize: '16px', paddingLeft: '0px' }} onClick={() => {
                const wps: RouteWaypoint[] = props.waypoints !== undefined ? [...props.waypoints!] : [];
                wps.push(props.destination!);
                props.setWaypoints(wps);
                props.setDestination(undefined);
            }}>
                {<AddCircleOutlineOutlined />} <span style={{ marginLeft: '5px' }}>Add Destination</span>
            </IconButton>}
        </div>
    );

    const swapButtonEnabled = props.origin || props.destination;
    let swapOriginDestinationButton: JSX.Element | undefined = undefined;
    if (props.waypoints === undefined) {
        swapOriginDestinationButton = (
            <div className={'textfield-buttons'}>
                <div className={'route-reverse-button'}>
                    <Tooltip title={'Reverse origin and destination'} placement="top">
                        <IconButton disabled={!swapButtonEnabled} onClick={() => {
                            const oldOrigin = props.origin;
                            const oldDestination = props.destination;
                            props.setOrigin(oldDestination);
                            props.setDestination(oldOrigin);
                        }}>
                            {<SwapVertOutlined />}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    }

    return (
        <div style={{ paddingTop: '12px', maxHeight: '50vh', overflowY: 'auto' }}>
            <div className={'waypoint-entry'}>
                <div className={'waypoint-entry-container'}>
                    {originInput}

                    {waypointInputs}

                    {destinationInput}

                    {addDestinationButton}
                </div>

                {swapOriginDestinationButton}
            </div>

            <div style={fieldStyle}>
                <DateTimePicker
                    label="Departure Time"
                    value={props.departureTime}
                    onChange={(newValue) => props.setDepartureTime(newValue)}
                    // we support up to 24 hours back
                    minDateTime={moment().subtract(24, 'hour')}
                    maxDateTime={moment().add(1, 'week')}
                    slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                />
            </div>

            <div>
                <Accordion style={{ backgroundColor: cardBackgroundColor, marginBottom: '20px', width: '99%' }}>
                    <AccordionSummary expandIcon={<GridExpandMoreIcon />} >
                        <Typography color='#c6c8c9'>+ Temperature Thresholds</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={fieldStyle}>
                            <TextField
                                label='Above Temperature Threshold'
                                type="number"
                                fullWidth
                                value={props.aboveTemperatureThreshold}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setAboveTemperatureThreshold(event.target.value === '' ? undefined : parseInt(event.target.value))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">&deg;F</InputAdornment>,
                                }}
                            />
                        </div>

                        <div style={fieldStyle}>
                            <TextField
                                label='Below Temperature Threshold'
                                type="number"
                                fullWidth
                                value={props.belowTemperatureThreshold}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBelowTemperatureThreshold(event.target.value === '' ? undefined : parseInt(event.target.value))}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">&deg;F</InputAdornment>,
                                }}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* highlight preview features with yellow border*/}
            {isPreviewDeploy && <div style={{ display: 'flex', width: 200, borderColor: '#FFFF0040', borderStyle: 'solid', borderWidth: 2 }}>
                <Tooltip title="Developer settings -- only shows up in preview builds" placement="right">
                    <FormControl>
                        <InputLabel>Vehicle Type</InputLabel>
                        <Select size="small" label="Vehicle Type" style={{ width: 200 }} value={props.vehicleType} onChange={e => props.setVehicleType(e.target.value as VehicleType)}>
                            {['car', 'truck'].map(vehicleType => (
                                <MenuItem key={vehicleType} value={vehicleType}>
                                    <Typography variant="caption">
                                        {capitalize(vehicleType)}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Tooltip>
            </div>}
        </div>
    );
};
